import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import FlexCard from "../../LoanApplications/Helpers/FlexCard";
import LoanDetailsModal from "./LoanDetailsModal";
import RepaymentModal from "../Repayments/RepaymentModal";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const Loans = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const frompath = props?.urlparams?.params?.frompath;
  const [loandetails, setLoandetails] = useState({});
  const [bankdata, setBankdata] = useState({});
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [repaymentHistoryData, setRepaymentHistoryData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const repaymentHistoryColumns = [
    {
      dataIndex: "loanAccountNumber",
      title: "LAN",
      render: (text, record) => {
        return <h6 className="text-primary">{text}</h6>;
      },
    },
    {
      dataIndex: "paymentDate",
      title: "Payment Date",
      render: (text, record) => {
        if (record?.paymentDate) {
          let date = new Date(record?.paymentDate).dateToDDMMYYYY(
            new Date(record?.paymentDate)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "paymentAmt",
      title: "Payment Amount",
      // sorter: (a, b) => a.paymentAmt - b.paymentAmt,
      render: (text, record) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "paymentMode",
      title: "Payment Mode",
      render: (text, record) => {
        return <>{text?.toTitleCase()}</>;
      },
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text, record) => {
        let status = record?.paymentStatus || "pending";
        let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

        return (
          <Dropdown className="status-dropdown pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              {status?.toTitleCase()}
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "paymentReferenceNum",
      title: "Payment Ref. No.",
    },
    {
      dataIndex: "totalAmt",
      title: "Total Amount",
      render: (text, record) => {
        return <>{text?.cur()}</>;
      },
    },
    // {
    //   dataIndex: "status",
    //   title: "Txn. Status",
    //   render: (text, record) => {
    //     let status = record?.status || "pending";
    //     let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

    //     return (
    //       <Dropdown className="status-dropdown pe-none">
    //         <Dropdown.Toggle as="div" className={mappedStatus}>
    //           {status?.toTitleCase()}
    //         </Dropdown.Toggle>
    //       </Dropdown>
    //     );
    //   },
    // },
  ];
  const [payinPayload, setPayinPayload] = useState(null);
  const formRef = useRef(null);
  // ----------------------------------------------------------------

  const [modalFields, setModalFields] = useState([
    {
      name: "loanAccountNumber",
      label: "Loan Application No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanAmount",
      label: "Loan Amt",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanTenure",
      label: "Tenure",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanIRR",
      label: "ROI",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanPF",
      label: "Processing Fee",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanStartDate",
      label: "Loan Start date",
      type: "date",
      dateClassName: "fakedisabled",
    },
    {
      name: "sanctionedLoanEndDate",
      label: "Loan End date",
      type: "date",
      dateClassName: "fakedisabled",
    },
    {
      label: "Interest",
      name: "loanInterestAmtTillDate",
      type: "text",
      readOnly: true,
    },
    {
      label: "Penal Interest",
      name: "penaltyInterestAmtTillDate",
      type: "text",
      readOnly: true,
    },
    {
      label: "Total Payable",
      name: "totalAmountPayableAmtTillDate",
      type: "text",
      readOnly: true,
    },
    {
      label: "Amount Received",
      name: "totalRepaymentAmtTillDate",
      type: "text",
      readOnly: true,
    },
    {
      label: "Outstanding",
      name: "totalOutstandingAmtTillDate",
      type: "text",
      readOnly: true,
    },
    { label: "Overdue", name: "overdue", type: "text", readOnly: true },
    { label: "Waiver", name: "waiverAmount", type: "number", step: ".01" },
    { label: "Remarks", name: "remarks", type: "text", customcolspan: 12 },
  ]);
  const [applicationAddressInfo, setApplicationAddressInfo] = useState({});
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [show, setShow] = useState(false);
  const [checkValue, setCheckValue] = useState("currentOutstanding");
  const [applicationEditdata, setApplicationEditdata] = useState({});
  const [calculatedData, setCalculatedData] = useState({});
  const [showCloseBtn, setShowCloseBtn] = useState(true);
  const [disableCloseAccountBtn, setDisableCloseAccountBtn] = useState(true);
  // ----------------------------------------------------------------

  const openModal = () => {
    setOpenCanvas(true);
    addModalFields();
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const editLoanDetails = (e, record) => {
    setEditmode(true);
    setViewmode(false);

    let filteredData = Object.only(
      record,
      modalFields.map((v) => v.name)
    );
    let updatedPopupData = {
      ...filteredData,
      loanInterestAmtTillDate:
        applicationEditdata?.loanInterestAmtTillDate?.toFixed(),
      totalAmountPayableAmtTillDate:
        applicationEditdata?.totalAmountPayableAmtTillDate?.toFixed(),
      amountReceived: applicationEditdata?.totalRepaymentAmtTillDate,
      penaltyInterestAmtTillDate:
        applicationEditdata?.penaltyInterestAmtTillDate?.toFixed(),
      sanctionedLoanTotalAmtPayable:
        applicationEditdata?.sanctionedLoanTotalAmtPayable,
      totalOutstandingAmtTillDate:
        applicationEditdata?.totalOutstandingAmtTillDate?.toFixed(),
      overdue: `${calculatedData?.overdueDays} days`,
      waiverAmount: applicationEditdata?.waiverAmount,
    };
    console.log({ record, filteredData, updatedPopupData });

    setPopupdata(updatedPopupData);
    setEditData(updatedPopupData);

    let updatedFields = modalFields?.map((field) => {
      if (field.type === "date") {
        return {
          ...field,
          defaultValue: dayjs(updatedPopupData[field?.name]),
        };
      }
      return {
        ...field,
        defaultValue: updatedPopupData[field?.name],
      };
    });
    setModalFields([...updatedFields]);
    openModal();
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log({ data });

    let payload = {
      ...Object.except(data, [
        "loanAccountNumber",
        "loanInterestAmtTillDate",
        "totalRepaymentAmtTillDate",
        "overdue",
        "totalOutstandingAmtTillDate",
        "penaltyInterestAmtTillDate",
        "totalAmountPayableAmtTillDate",
      ]),
      sanctionedLoanStartDate: new Date(
        data?.sanctionedLoanStartDate
      ).toISOString(),
      sanctionedLoanEndDate: new Date(
        data?.sanctionedLoanEndDate
      ).toISOString(),
      waiverAmount: Number(data?.waiverAmount),
      sanctionedLoanAmount: Number(data?.sanctionedLoanAmount),
      sanctionedLoanTenure: Number(data?.sanctionedLoanTenure),
      sanctionedLoanIRR: Number(data?.sanctionedLoanIRR),
      sanctionedLoanPF: Number(data?.sanctionedLoanPF),
      sanctionedLoanTotalAmtPayable: Number(
        applicationEditdata?.sanctionedLoanTotalAmtPayable
      ),
      //
      borrowerName: applicationEditdata?.borrowerName,
      loanPaymentMode: applicationEditdata?.loanPaymentMode,
      stampDutyNum: applicationEditdata?.stampDutyNum,
      mobileNumber: applicationEditdata?.mobileNumber,
      emailAddress: applicationEditdata?.emailAddress,
      netDisbursementAmt: applicationEditdata?.netDisbursementAmt,
    };
    console.log({ payload, editData });

    props.apis
      .apiUnderWriterGenerateAgreement(payload, {
        loanAccountNumber: data.loanAccountNumber,
      })
      .then((res) => {
        swal2.fire({
          title: "Loan Details updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getLoanDetails();
      })
      .catch((error) => console.log(error));
  };

  const handleRunInterest = () => {
    props.apis
      .apiAdminCalculateInterest({
        loanAccountNumber: applicationEditdata.loanAccountNumber,
      })
      .then((res) => {
        if (res) {
          console.log({ res });
          setCalculatedData(res?.summary);
          setLoandetails((prevstate) => {
            return {
              ...prevstate,
              interest: res?.summary?.totalNormalInterestAmt?.cur(),
              penal_interest: res?.summary?.totalPenaltyInterestAmt?.cur(),
              outstanding: res?.summary?.totalOutstandingAmount?.cur(),
              amountReceived: res?.summary?.totalRepayment?.cur(),
              totalPayable: res?.summary?.totalAmountPayable?.cur(),
              overdue: `${res?.summary?.overdueDays} days`,
              waiverAmount: res?.summary?.waiverAmount,
              closer_date: "",
            };
          });
          getLoanDetails();
        }
      });
  };

  // ----------------------------------------------------------------
  // APPLICATION PAY NOW FLOW
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [editdata, setEditdata] = useState({});
  const [checkPaymentValue, setCheckPaymentValue] =
    useState("currentOutstanding");

  const handlePaymentShow = (e) => {
    setShowPaymentModal(true);
  };

  const handlePaymentClose = (e) => {
    setShowPaymentModal(false);
  };

  const handlePaymentChange = (e) => {
    e.persist();
    let value = e.target.value;
    setCheckPaymentValue(value);
  };

  const handlePaymentSubmit = (e, data) => {
    let payload = {
      loanAccountNumber: props.urlparams.params.lanid || "",
    };

    if (checkPaymentValue === "currentOutstanding") {
      payload.amount = Number(editdata?.totalOutstandingAmtTillDate);
      if (Number(editdata?.totalOutstandingAmtTillDate) === 0) {
        swal2.fire({
          title: "Currently there's no outstanding amount",
          icon: "warning",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        return;
      }
    } else {
      payload.amount = Number(data?.amount);
    }
    console.log({ editdata, payload, data });

    const resolve = () => {
      handlePaymentClose();
      getLoanDetails();
      getApplication();
      getRepaymentHistory();
    };
    props.apis
      .apiPayinGenerateLink(payload)
      .then(async (res) => {
        if (res) {
          console.log({ res });
          await navigator.clipboard.writeText(res?.data?.payment_url);
          swal2.fire({
            title: "Payment link copied to clipboard successfully.",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 4000,
          });
          resolve();
        }
      })
      .catch((err) => {
        resolve();
      });
  };
  // ----------------------------------------------------------------
  const [showReloanModal, setShowReloanModal] = useState(false);
  const [reloanFields, setReloanFields] = useState([
    {
      name: "reLoanEligibility",
      label: "Case is eligible for Re-loan?(Y/N)",
      type: "select",
      options: _enum.YES_OR_NO_BOOLEAN,
      required: true,
    },
  ]);
  const handleReloanClose = () => setShowReloanModal(false);

  const handleReloanSubmit = (e, data) => {
    let payload = {
      reLoanEligibility: JSON.parse(data?.reLoanEligibility),
    };

    let urlData = {
      loanAccountNumber: applicationEditdata.loanAccountNumber,
    };

    console.log({ urlData, payload });

    swal2.fire({
      text: "Loan Account Closed Successfully",
      icon: "success",
      iconColor: "#FF4D4D",
      timer: 4000,
      confirmButtonText: "Close",
    });

    props.apis
      .apiAdminLoanClosureAfterRepayment(payload, urlData)
      .then((res) => {
        if (res) {
          navigate("/app/loandetails?activetab=active");
          handleReloanClose();
        }
      });
  };

  const handleCloseAccount = () => {
    swal2
      .fire({
        title: "Are you sure want to close this loan?",
        icon: "warning",
        iconColor: "#DE3232",
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setShowReloanModal(true);
        }
      });
  };

  const getApplication = () => {
    let payload = {
      applicationId: props.urlparams.params.id,
    };
    props.apis.apiGetCustomerApplicationById(payload).then((res) => {
      setApplicationEditdata(res);
      setIsFetching(false);
    });
  };

  const getLoanDetails = () => {
    props.apis
      .apiGetUnderWriterLoanApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        // res?.totalOutstandingAmtTillDate
        setEditdata(res);
        let value = res?.totalOutstandingAmtTillDate;
        if (value === null || value === "") {
          setDisableCloseAccountBtn(true);
        } else if (value <= 0) {
          setDisableCloseAccountBtn(false);
        } else {
          setDisableCloseAccountBtn(true);
        }
        setApplicationAddressInfo({
          ...Object.only(res, [
            "loanAccountNumber",
            "sanctionedLoanAmount",
            "sanctionedLoanTenure",
            "sanctionedLoanIRR",
            "sanctionedLoanPF",
            "sanctionedLoanTotalAmtPayable",
            "remarks",
            "waiverAmount",
            "amountReceived",
          ]),
          sanctionedLoanStartDate: res?.sanctionedLoanStartDate,
          sanctionedLoanEndDate: res?.sanctionedLoanEndDate,
        });
        setLoandetails((prevstate) => {
          return {
            ...prevstate,
            loan_application_no: res?.loanAccountNumber,
            loan_amt: res?.sanctionedLoanAmount?.cur(),
            tenure: res?.sanctionedLoanTenure,
            roi: res?.sanctionedLoanIRR,
            processing_fee: res?.sanctionedLoanPF?.cur(),
            loan_start_date: new Date(
              res?.sanctionedLoanStartDate
            ).dateToDDMMYYYY(new Date(res?.sanctionedLoanStartDate)),
            loan_end_date: new Date(res?.sanctionedLoanEndDate).dateToDDMMYYYY(
              new Date(res?.sanctionedLoanEndDate)
            ),
            loan_agreement: res?.loanAgreementFileId,
            interest: res.loanInterestAmtTillDate?.toFixed(),
            penal_interest: res.penaltyInterestAmtTillDate?.toFixed(),
            totalPayable: res.totalAmountPayableAmtTillDate?.toFixed(),
            amountReceived: res.totalRepaymentAmtTillDate,
            outstanding: res.totalOutstandingAmtTillDate?.toFixed(),
            overdue: prevstate.overdue,
            waiverAmount:
              res?.waiverAmount?.cur() || prevstate.waiverAmount?.cur(),
            status: res?.loanAccountStatus,
            closer_date:
              res?.loanClosingDate !== null
                ? new Date(res?.loanClosingDate).dateToDDMMYYYY(
                    new Date(res?.loanClosingDate)
                  )
                : "",
            remarks: res?.remarks,
          };
        });
        setBankdata({
          account_holder_name: res?.accountHolderName,
          account_no: res?.accountNumber,
          bank_name: res?.accountHolderBankName?.toTitleCase(),
          ifsc_code: res?.accountHolderBankIFSC,
          payment_ref_no: res?.loanAccountPayoutTnx?.[0]?.bankReferenceNumber,
          payment_date: new Date(res?.disbursementDate).dateToDDMMYYYY(
            new Date(res?.disbursementDate)
          ),
          payment_status: res?.loanAccountPayoutTnx?.[0]?.transactionStatus,
        });
      });
  };

  useEffect(() => {
    console.log({ calculatedData, applicationEditdata });
    if (applicationEditdata?.totalOutstandingAmtTillDate <= 0) {
      setDisableCloseAccountBtn(false);
    } else {
      setDisableCloseAccountBtn(true);
    }
  }, [calculatedData]);

  // ----------------------------------------------------------------
  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handlePayment = (e) => {
    e.persist();
    let value = e.target.value;
    setCheckValue(value);
  };

  const handleRepaymentSubmit = (e, data) => {
    let payload = {
      loanAccountNumber: applicationEditdata.loanAccountNumber || "",
      redirecturl: `${endpoints[environment].baseFE_URL}app/loandetails`,
    };

    if (checkValue === "currentOutstanding") {
      payload.paymentAmt = applicationEditdata.otherAmount || "";
    } else {
      payload.paymentAmt = data?.amount;
    }

    console.log({ payload });

    const resolve = () => {
      handleClose();
      getApplication();
    };

    props.apis
      .apiPayinByCustomer(payload)
      .then((res) => {
        setPayinPayload(res);
      })
      .catch((err) => {
        console.log(err);
        resolve();
      });
  };
  // ----------------------------------------------------------------
  const [repaymentEditdata, setRepaymentEditdata] = useState({});
  const [repaymentEditmode, setRepaymentEditmode] = useState(false);
  const [repaymentViewmode, setRepaymentViewmode] = useState(false);
  const [repaymentOpenCanvas, setRepaymentOpenCanvas] = useState(false);
  const [repaymentPopupfields, setRepaymentPopupfields] = useState({});
  const [repaymentPopupdata, setRepaymentPopupdata] = useState({});

  const [repaymentModalFields, setRepaymentModalFields] = useState([
    {
      name: "loanAccountNumber",
      label: "Loan Account Number",
      type: "text",
      readOnly: true,
      defaultValue: props.urlparams.params.lanid,
    },
    {
      name: "paymentDate",
      label: "Payment Date",
      type: "date",
      required: true,
    },
    {
      name: "dueDate",
      label: "Due Date",
      type: "date",
      required: true,
    },
    {
      name: "paymentAmt",
      label: "Payment Amt",
      type: "number",
      required: true,
    },
    {
      name: "lateFeeAmt",
      label: "Late Fee Amt",
      type: "number",
      required: true,
    },
    {
      name: "totalAmt",
      label: "Total Amt",
      type: "number",
      required: true,
    },
    {
      name: "paymentMode",
      label: "Payment Mode",
      type: "select",
      options: _enum.REPAYMENT_MODES,
      required: true,
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      type: "select",
      options: _enum.REPAYMENT_STATUS,
      required: true,
    },
    {
      name: "paymentReferenceNum",
      label: "Payment Reference Number",
      type: "text",
      required: true,
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
      customcolspan: 12,
    },
  ]);

  const openRepaymentModal = () => {
    setRepaymentOpenCanvas(true);
  };

  const closeRepaymentModal = () => {
    setRepaymentOpenCanvas(false);
  };

  const addRepaymentModalFields = (values) => {
    let fields = (repaymentModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setRepaymentPopupfields(fields);
    setRepaymentPopupdata(formdata);
    setRepaymentOpenCanvas(true);
  };

  const onManualRepaymentModalClose = () => {
    console.log("onModalClose");
    closeRepaymentModal();
    setRepaymentPopupfields({});
    setRepaymentViewmode(false);
    setRepaymentEditmode(false);
    setRepaymentEditdata({});
    // setErrorMessage({ name: "", message: "" });
  };

  const viewManualRepayment = (record) => {
    // console.log({ record });
    setRepaymentEditmode(false);
    setRepaymentViewmode(true);
  };

  const editManualRepayment = (record) => {
    // console.log({ record });
    setRepaymentEditmode(true);
    setRepaymentViewmode(false);
  };

  const getRepaymentHistory = (record) => {
    let payload = {
      loanAccountNumber: props.urlparams.params.lanid,
    };
    props.apis.apiAdminGetAllLoanRepayments(payload).then((res) => {
      setRepaymentHistoryData(res);
    });
  };

  const handleManualRepaymentSubmit = (e, data, form) => {
    console.log({ data });
    let payload = {
      loanAccountNumber: data?.loanAccountNumber,
      paymentDate: new Date(data.paymentDate).toISOString(),
      dueDate: new Date(data.dueDate).toISOString(),
      paymentAmt: Number(data?.paymentAmt),
      lateFeeAmt: Number(data?.lateFeeAmt),
      totalAmt: Number(data?.totalAmt),
      paymentMode: data?.paymentMode,
      paymentStatus: data?.paymentStatus,
      paymentReferenceNum: data?.paymentReferenceNum,
      remarks: data?.remarks,
    };
    console.log({ payload });

    props.apis.apiAdminAddLoanPayment(payload).then((res) => {
      console.log({ res });
      closeRepaymentModal();
      getRepaymentHistory();
    });
  };

  useEffect(() => {
    let fields = (repaymentModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setRepaymentPopupfields(fields);
  }, [repaymentEditdata]);

  const getBankLink = () => {
    let link;
    if (frompath === "loandetails") {
      link = `/app/loandetails?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    getLoanDetails();
    getApplication();
    getRepaymentHistory();
  }, []);

  return (
    <>
      <div className="card p-4">
        <FlexCard
          title="Loan Details"
          data={loandetails}
          hasPdfFile={true}
          pdfIdKey="loan_agreement"
          {...(isAdmin
            ? {
                editable: isReadonly ? false : true,
                onEdit: (...args) =>
                  editLoanDetails(...args, applicationAddressInfo),
              }
            : {})}
        />
        {isAdmin && (
          <div
            className={Object.className({
              "nav_container mt-4": true,
              "d-none": isReadonly,
            })}
          >
            <button
              type="submit"
              className="btn btn-primary me-2"
              onClick={(...args) => handlePaymentShow(...args)}
            >
              Generate Payment Link
            </button>
            <button
              type="submit"
              className="btn btn-primary me-2"
              onClick={(...args) => handleRunInterest(...args)}
            >
              Run Interest
            </button>
            <button
              type="button"
              className="btn btn-primary-lite ms-2"
              onClick={(...args) => handleCloseAccount(...args)}
              disabled={disableCloseAccountBtn}
            >
              Close Account
            </button>
          </div>
        )}
      </div>
      <div className="card p-4">
        <div className="form_group_card px-0">
          <div className="d-flex align-items-start justify-content-between">
            <h2 className="mb-3 theme_text_sm">Repayment History</h2>
            {isAdmin && (
              <button
                className={Object.className({
                  "btn btn-primary mb-4": true,
                  "d-none": isReadonly,
                })}
                // onClick={(...args) => handleShow(...args)}
                onClick={(...args) => addRepaymentModalFields(...args)}
              >
                + Manual Repayments
              </button>
            )}
          </div>
          <div>
            <AppTable
              data={repaymentHistoryData}
              columns={repaymentHistoryColumns}
              // onChange={(...arg) => onPageChange(...arg)}
              total={rowcount}
              reorder={true}
              {...(true
                ? {
                    editable: false,
                    viewable: false,
                  }
                : {
                    editable: true,
                    viewable: true,
                  })}
              viewAction={(...args) => viewManualRepayment(...args)}
              editAction={(...args) => editManualRepayment(...args)}
              deletable={false}
              loading={isFetching}
            ></AppTable>
          </div>
        </div>
      </div>
      <div className="card p-4">
        <FlexCard
          title="Bank A/C Details"
          data={bankdata}
          colspan_key="col-3"
        />
      </div>
      {isReadonly && (
        <div className="nav_container mt-5">
          <Link to={getBankLink()} className="btn btn-primary-lite me-3">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "personalinfo")}
          >
            Next
          </button>
        </div>
      )}
      <form
        ref={formRef}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        action="https://pg.icepe.in/paymentrequest"
        method="post"
        className="flex flex-col gap-4"
      >
        {payinPayload &&
          Object.keys(payinPayload).map((key) => (
            <input
              key={key}
              type="hidden"
              name={key}
              value={payinPayload[key]}
            />
          ))}
      </form>
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal payment_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleRepaymentSubmit}>
            <div className="payment_modal_content_box">
              <p className="content_title">How much you want to pay?</p>
              <div className="checkbox_container mb-4 flex-column">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="currentOutstanding"
                    name="communicationAddress"
                    id="currentOutstanding"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "currentOutstanding"}
                  />
                  <label class="form-check-label" for="currentOutstanding">
                    Current Outstanding
                    {applicationEditdata?.totalOutstandingAmtTillDate
                      ? ` of ${applicationEditdata?.totalOutstandingAmtTillDate?.cur()}`
                      : ""}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="otherAmount"
                    name="communicationAddress"
                    id="otherAmount"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "otherAmount"}
                  />
                  <label class="form-check-label" for="otherAmount">
                    Other Amount
                  </label>
                </div>
              </div>
            </div>
            {checkValue === "otherAmount" && (
              <AppInput name="amount" label="Amount" type="number"></AppInput>
            )}
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Pay Now
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
      <LoanDetailsModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Loan Details"
            : viewmode
            ? "View Loan Details"
            : "Add Loan Details"
        }`}
        show={openCanvas}
        onClose={(...args) => onModalClose(...args)}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
      <RepaymentModal
        id="employeeCanvas"
        title={`${
          repaymentViewmode
            ? "Edit Manual Repayments"
            : viewmode
            ? "View Manual Repayments"
            : "Add Manual Repayments"
        }`}
        show={repaymentOpenCanvas}
        onClose={() => onManualRepaymentModalClose()}
        data={repaymentPopupdata}
        editdata={repaymentEditdata}
        fields={repaymentPopupfields}
        editmode={repaymentEditmode}
        onSubmit={(...args) => handleManualRepaymentSubmit(...args)}
        submitText={`${repaymentEditmode ? "Update" : "Submit"}`}
        disableallfields={repaymentViewmode ? true : false}
        // errorMessage={errorMessage}
      />
      {/* RELOAN MODAL */}
      <Modal
        show={showReloanModal}
        onHide={(...args) => handleReloanClose(...args)}
        className="customer_application_modal br-normal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Re-loan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleReloanSubmit}>
            {reloanFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => handleReloanClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
      {/*  */}

      <Modal
        show={showPaymentModal}
        onHide={(...args) => handlePaymentClose(...args)}
        className="customer_application_modal payment_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handlePaymentSubmit}>
            <div className="payment_modal_content_box">
              <p className="content_title">How much you want to collect?</p>
              <div className="checkbox_container mb-4 flex-column">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="currentOutstanding"
                    name="communicationAddress"
                    id="currentOutstanding"
                    onChange={(...args) => handlePaymentChange(...args)}
                    checked={checkPaymentValue === "currentOutstanding"}
                  />
                  <label class="form-check-label" for="currentOutstanding">
                    Current Outstanding
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="otherAmount"
                    name="communicationAddress"
                    id="otherAmount"
                    onChange={(...args) => handlePaymentChange(...args)}
                    checked={checkPaymentValue === "otherAmount"}
                  />
                  <label class="form-check-label" for="otherAmount">
                    Other Amount
                  </label>
                </div>
              </div>
            </div>
            {checkPaymentValue === "otherAmount" && (
              <AppInput name="amount" label="Amount" type="number"></AppInput>
            )}
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handlePaymentClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Pay Now
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(Loans);
