export default {
  apiGetDashboardMoke() {
    let data = {
      leads_entered: {
        number_of_leads: 100,
        amount: 800000,
      },
      approved_leads: {
        number_of_leads: 10,
        amount: 500000,
      },
      disbursement_leads: {
        number_of_leads: 6,
        amount: 80000,
      },
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiAddApplication(data) {
    return this.request(this.api().ADD_APPLICATION, data).exec().log().get();
  },

  apiGetApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_APPLICATIONS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetApplicationById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_APPLICATION_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiUpdateApplication(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    let payload = {
      leadId: data?.leadId,
      leadName: data?.leadName,
      mobileNumber: data?.mobileNumber,
      productType: data?.productType,
      bankName: data?.bankName,
      applicationDate: data?.applicationDate,
      loanAmount: data?.loanAmount,
      status: data?.status,
      source: data?.source,
      remarks: data?.remarks,
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().UPDATE_APPLICATION, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiDeleteApplication(urldata = mixins.params()) {
    return this.request(this.api().DELETE_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiReopenApplication(urldata = mixins.params()) {
    return this.request(this.api().REOPEN_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiSoftDeleteApplication(urldata = mixins.params()) {
    return this.request(this.api().SOFT_DELETE_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiPayinByCustomer(data) {
    return this.request(this.api().PAYIN, data).exec().log().get();
  },
  apiPayinGenerateLink(data) {
    return this.request(this.api().PAYIN_GENERATE_LINK, data).exec().log().get();
  },


  apiGetPayinGenerateLink(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().PAYIN_GET_GENERATE_LINK)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiSendLoanAmountToCustomer(urldata = mixins.params()) {
    return this.request(this.api().SEND_LOAN_AMOUNT_TO_CUSTOMER)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiAdminAddManualDisbursement(data, urldata = mixins.params()) {
    return this.request(this.api().ADMIN_MANUALLY_ADD_CUSTOMER_PAYOUT_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetCustomerPayinTransactions(data) {
    return this.request(this.api().GET_CUSTOMER_PAYIN_TRANSACTIONS, data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetTransactionStatus(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADMIN_FETCH_TRANSACTION_STATUS)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiAdminGetWalletBalance(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADMIN_GET_CURRENT_WALLET_BALANCE)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  // 
  apiAdminGetAllPayoutTransactions(data) {
    return this.request(this.api().ADMIN_GET_ALL_PAYOUT_TRANSACTIONS, data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },
};