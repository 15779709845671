export default {
  UNDERWRITER_GET_UNASSIGNED_APPLICATIONS: {
    url: "/api/v1/underwriter/application/unassigned",
    method: "get",
  },
  UNDERWRITER_GET_REJECTED_APPLICATIONS: {
    url: "/api/v1/underwriter/application/rejected",
    method: "get",
  },
  UNDERWRITER_GET_INPROGRESS_APPLICATIONS: {
    url: "/api/v1/underwriter/application/progress",
    method: "get",
  },
  UNDERWRITER_GET_SANCTIONED_APPLICATIONS: {
    url: "/api/v1/underwriter/application/sanctioned",
    method: "get",
  },
  UNDERWRITER_GET_APPROVED_APPLICATIONS: {
    url: "/api/v1/underwriter/application/approved",
    method: "get",
  },
  UNDERWRITER_REASSIGN_APPLICATION: {
    url: "/api/v1/underwriter/application/reassign",
    method: "post",
  },
  UNDERWRITER_SELF_ASSIGN_APPLICATION: {
    url: "/api/v1/underwriter/application/assign/{applicationNumber}",
    method: "post",
  },
  UNDERWRITER_GET_APPLICATION_DETAILS: {
    url: "/api/v1/underwriter/application/single/{applicationNumber}",
    method: "get",
  },
  UNDERWRITER_UPDATE_APPLICATION_ADDRESS: {
    url: "/api/v1/underwriter/application/update-address/{addressId}",
    method: "put",
  },
  UNDERWRITER_UPDATE_APPLICATION_INCOME_DETAILS: {
    url: "/api/v1/underwriter/application/update-income-details/{applicationSalaryId}",
    method: "put",
  },
  UNDERWRITER_GENERATE_BSA_REPORT: {
    url: "/api/v1/loan-bgv/bsa-signzy/analyze/{applicationNumber}",
    method: "post",
  },
  UNDERWRITER_VERIFY_BSA_STATUS: {
    url: "/api/v1/loan-bgv/bsa-signzy/status/{applicationNumber}",
    method: "get",
  },
  UNDERWRITER_GET_BSA_REPORT: {
    url: "/api/v1/loan-bgv/bsa-signzy/report/{applicationNumber}",
    method: "get",
  },
  UNDERWRITER_PENNY_DROP_PAYMENT: {
    url: "/api/v1/signdesk-penny-drop/payment",
    method: "post",
  },
  UNDERWRITER_CHECK_PENNY_DROP_STATUS: {
    url: "/api/v1/signdesk-penny-drop/status",
    method: "post",
  },
  UNDERWRITER_GET_APPLICATION_FINAL_REVIEW: {
    url: "/api/v1/underwriter/application/sanction/{applicationId}",
    method: "get",
  },
  UNDERWRITER_ADD_ADDITIONAL_DOCS: {
    url: "/api/v1/underwriter/application/additional-docs/{applicationId}",
    method: "post",
  },
  UNDERWRITER_UPDATE_ADDITIONAL_DOCS: {
    url: "/api/v1/underwriter/application/additional-docs/{applicationId}",
    method: "put",
  },
  UNDERWRITER_DELETE_ADDITIONAL_DOCS: {
    url: "/api/v1/underwriter/application/additional-docs/{applicationId}/{fileId}",
    method: "delete",
  },
  UNDERWRITER_UPDATE_PERMANENT_ADDRESS_INFO: {
    url: "/api/v1/underwriter/application/address/{addressId}",
    method: "put",
  },
  UNDERWRITER_UPDATE_CUSTOMER_REFERENCE_DETAILS: {
    url: "/api/v1/underwriter/application/reference-details/{applicationId}",
    method: "put",
  },
  UNDERWRITER_UPDATE_CUSTOMER_ACCOUNT_DETAILS: {
    url: "/api/v1/underwriter/application/account-details/{applicationId}",
    method: "put",
  },
  UNDERWRITER_APPLICATION_DEDUP_CHECK: {
    url: "/api/v1/underwriter/application/dedup/check",
    method: "get",
  },
  UNDERWRITER_APPLICATION_STATUS_HISTORY: {
    url: "/api/v1/underwriter/application/history/{applicationId}",
    method: "get",
  },
  UNDERWRITER_CREATE_APPLICATION_QUERY: {
    url: "/api/v1/application/queries",
    method: "post",
  },
  UNDERWRITER_GET_APPLICATION_QUERY: {
    url: "/api/v1/application/queries/{applicationNumber}",
    method: "get",
  },
  UNDERWRITER_GET_CIBIL_DETAILS: {
    url: "/api/v1/loan-bgv/cibil/{applicationNumber}",
    method: "get",
  },
  UNDERWRITER_GENERATE_CIBIL_REPORT: {
    url: "/api/v1/loan-bgv/cibil/{applicationNumber}",
    method: "post",
  },
  UNDERWRITER_ADD_CIBIL_ADDITIONAL_INFO: {
    url: "/api/v1/loan-bgv/cibil/add-info",
    method: "post",
  },
  UNDERWRITER_GET_CIBIL_ADDITIONAL_INFO: {
    url: "/api/v1/loan-bgv/cibil/add-info/{applicationEquifaxCibilId}",
    method: "get",
  },
  UNDERWRITER_UPDATE_CIBIL_ADDITIONAL_INFO: {
    url: "/api/v1/loan-bgv/cibil/{cibilAdditionalInfoId}",
    method: "put",
  },
  UNDERWRITER_DELETE_CIBIL_ADDITIONAL_INFO: {
    url: "/api/v1/loan-bgv/cibil/add-info/{cibilAdditionalInfoId}",
    method: "delete",
  },
  UNDERWRITER_ADD_BSA_INFO: {
    url: "/api/v1/loan-bgv/bsa/solo/upload",
    method: "post",
  },
  UNDERWRITER_GET_BSA_ENQUIRIES: {
    url: "/api/v1/loan-bgv/bsa/solo/{statement_id}",
    method: "get",
  },
  UNDERWRITER_ACCEPT_QUERY_RESPONSE: {
    url: "/api/v1/application/queries/accept/{conversationId}",
    method: "put",
  },
  UNDERWRITER_REJECT_QUERY_RESPONSE: {
    url: "/api/v1/application/queries/reject/{conversationId}",
    method: "put",
  },
  UNDERWRITER_SANCTION_APPLICATION: {
    url: "/api/v1/underwriter/application/sanction/{applicationId}",
    method: "put",
  },
  UNDERWRITER_REJECT_APPLICATION: {
    url: "/api/v1/underwriter/application/reject/{applicationId}",
    method: "put",
  },
  UNDERWRITER_APPROVE_POST_SANCTION_APPLICATION: {
    url: "/api/v1/underwriter/application/approve/{applicationId}",
    method: "put",
  },
  UNDERWRITER_APPROVE_PRE_DISBURSE_APPLICATION: {
    url: "/api/v1/underwriter/application/predisburse/{applicationIds}",
    method: "put",
  },
  UNDERWRITER_ADD_SALARY_CREDIT_INFO: {
    url: "/api/v1/underwriter/application/salary-credits",
    method: "post",
  },
  UNDERWRITER_UPDATE_SALARY_CREDIT_INFO: {
    url: "/api/v1/underwriter/application/salary-credits/{salaryCreditId}",
    method: "put",
  },

  // LOAN ACCOUNTS
  UNDERWRITER_GENERATE_AGREEMENT: {
    url: "/api/v1/accounts/agreement/{loanAccountNumber}",
    method: "put",
  },
  UNDERWRITER_REGENERATE_AGREEMENT: {
    url: "/api/v1/accounts/reagreement/{loanAccountNumber}",
    method: "put",
  },
  GET_LOAN_APPLICATION_DETAILS: {
    url: "/api/v1/accounts/application/{applicationNumber}",
    method: "get",
  },
  GET_ALL_LOAN_ACCOUNTS: {
    url: "/api/v1/accounts",
    method: "get",
  },
  GET_ALL_PREDISBURSED_APPLICATIONS: {
    url: "/api/v1/accounts/application/predisbursed",
    method: "get",
  },
  GET_PREDISBURSED_APPLICATION_BY_NUMBER: {
    url: "/api/v1/accounts/single/{loanAccountNumber}",
    method: "get",
  },
  GET_LOAN_ACCOUNT_BY_ID: {
    url: "/api/v1/accounts/application/{applicationId}",
    method: "get",
  },
  GET_ACTIVE_LOANS: {
    url: "/api/v1/accounts/active",
    method: "get",
  },
  GET_CLOSED_LOANS: {
    url: "/api/v1/accounts/closed",
    method: "get",
  },
  ADMIN_LOAN_CLOSURE_AFTER_REPAYMENT: {
    url: "/api/v1/accounts/close/{loanAccountNumber}",
    method: "put"
  },
  ADMIN_GENERATE_NOC_TO_CUSTOMER: {
    url: "/api/v1/accounts/noc/{loanAccountNumber}",
    method: "get"
  },
  // LOAN REPAYMENTS
  ADMIN_ADD_LOAN_REPAYMENT: {
    url: "/api/v1/loan/repayment",
    method: "post",
  },
  GET_ALL_LOAN_PAYMENTS: {
    url: "/api/v1/loan/repayment/{loanAccountNumber}",
    method: "get",
  },
  ADMIN_CALCULATE_INTEREST: {
    url: "/api/v1/loan/repayment/interest/{loanAccountNumber}",
    method: "get",
  },

  //Signzy: Bank Statement Analysis
  UNDERWRITER_BSA_UPLPAD: {
    url: "/api/v1/bank-statement-analysis/upload",
    method: "post",
  },
  UNDERWRITER_GET_BSA_INFO: {
    url: "/api/v1/bank-statement-analysis/upload",
    method: "get",
  },
  UNDERWRITER_ANALYZE_BSA: {
    url: "/api/v1/bank-statement-analysis/analyze",
    method: "post",
  },
  UNDERWRITER_REFRESH_BSA_STATUS: {
    url: "/api/v1/bank-statement-analysis/refresh/{workOrderId}",
    method: "put",
  },
  UNDERWRITER_GET_BSA_REPORT_SOLO: {
    url: "/api/v1/bank-statement-analysis/report/{workOrderId}",
    method: "get",
  },
};