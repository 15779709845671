import React, { Component, useEffect, useRef, useState } from "react";
//Import Components
import MainPagetitle from "../../layouts/MainPagetitle";
import ProductCard from "./elements/ProductCard";
import LeadsCenteredModal from "./Leads/LeadsCenteredModal";
import ProductModal from "./Services/ProductModal";
import { Card, Dropdown, Modal } from "react-bootstrap";
import FlexCard from "./LoanApplications/Helpers/FlexCard";

const CustomerHome = (props) => {
  const changePasswordModalRef = useRef(0);
  const [dashboardData, setDashboardData] = useState([]);
  const [activeApplicationData, setActiveApplicationData] = useState([]);
  const [hasActiveLoan, setHasActiveLoan] = useState(false);
  const [popupChangePasswordFields, setPopupChangePasswordFields] = useState(
    {}
  );
  const [changepasswordData, setChangepasswordData] = useState({});
  const [changepasswordFields, setChangepasswordFields] = useState([
    {
      label: "Old Password",
      name: "oldPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "New Password ",
      name: "newPassword",
      type: "password",
      customcolspan: "12",
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      customcolspan: "12",
    },
  ]);
  const [isFetching, setIsFetching] = useState(true);
  const [applications, setApplications] = useState([]);
  const [isFastLoanEligible, seFisfastLoanEligible] = useState(false);
  const [editdata, setEditdata] = useState({});

  const columns = [
    {
      dataIndex: "applicationId",
      title: "Loan ID",
      render: (text, record) => {
        return <span className="text-primary fw-bold">{text}</span>;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmt",
      title: "Sanctioned Loan Amount",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedTenure",
      title: "Sanctioned Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Applied On",
      render: (text, record) => {
        if (record?.createdAt) {
          let date = new Date(record?.createdAt).dateToDDMMYYYY(
            new Date(record?.createdAt)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let status = record?.status || "pending";
        let mappedStatus = _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

        return (
          <Dropdown className="status-dropdown pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              {status?.toTitleCase()}
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  // PRODUCT

  const products = [
    {
      title: "Upto Rs. 50,000",
      desc: "Short-term loans are small-scale financial..",
      image: imagepaths.peoplechattingImg,
      buttonType: "active",
      loanType: "short_term_loan",
    },
    {
      title: "Pre Qualified Offer",
      desc: "You are eligible for Re-loan",
      image: imagepaths.smileyCoupleImg,
      buttonType: "callback",
      buttonText: "Avail Now",
      loanType: "offer",
      directlink: `/app/loancalculationdocs?id=${editdata?.applicationId}&path=customerhome`,
    },
    {
      title: "Upto Rs. 1 Lac",
      desc: "Quick 1 Lac short-term loans offer immediate funds..",
      image: imagepaths.smileyCoupleImg,
      buttonType: "inactive",
      loanType: "long_term_loan",
    },
    {
      title: "Upto Rs. 2 Lac",
      desc: "Short-term loans up to ₹2 lac provide swift access..",
      image: imagepaths.shakehandsImg,
      buttonType: "inactive",
      loanType: "long_term_loan",
    },
  ];

  const getApplications = () => {
    props.apis.apiGetCustomerApplications().then(({ data, total }) => {
      setApplications(data);
      setIsFetching(false);
    });

    props.apis.apiGetCustomerActiveApplication().then((res) => {
      setEditdata(res);
      setActiveApplicationData({
        appl_details: {
          loan_Id: res?.loanAccountNumber,
          loan_amount: res?.sanctionedLoanAmount?.cur(),
          total_payable: res?.totalAmountPayableAmtTillDate?.cur(),
          amount_paid: res?.totalRepaymentAmtTillDate?.cur(),
          outstanding: res?.totalOutstandingAmtTillDate?.cur(),
          loan_agreement: res?.loanAgreementFileId,
        },
      });
      setHasActiveLoan(res?.loanAccountNumber ? true : false);
    });
  };

  // ----------------------------------------------------------------
  // APPLICATION PAY NOW FLOW
  const [show, setShow] = useState(false);
  const [checkValue, setCheckValue] = useState("currentOutstanding");

  const handleShow = (e) => {
    props.apis
      .apiAdminCalculateInterest({
        loanAccountNumber: editdata.loanAccountNumber,
      })
      .then((res) => {
        if (res) {
          console.log({ res });
          getApplications();
        }
      });
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handlePayment = (e) => {
    e.persist();
    let value = e.target.value;
    setCheckValue(value);
  };

  const handleSubmit = (e, data) => {
    let payload = {
      loanAccountNumber: editdata.loanAccountNumber || "",
    };

    if (checkValue === "currentOutstanding") {
      payload.amount = Number(editdata?.totalOutstandingAmtTillDate);
      if (Number(editdata?.totalOutstandingAmtTillDate) === 0) {
        swal2.fire({
          title: "Currently there's no outstanding amount",
          icon: "warning",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        return;
      }
    } else {
      payload.amount = Number(data?.amount);
    }
    // console.log({ editdata, payload });

    const resolve = () => {
      handleClose();
      getApplications();
    };
    props.apis
      .apiPayinByCustomer(payload)
      .then((res) => {
        if (res) {
          let atag = document.createElement("a");
          if (environment === "production") {
            atag.href = `https://pay.easebuzz.in/pay/${res?.data}`;
          } else {
            atag.href = `https://testpay.easebuzz.in/pay/${res?.data}`;
          }
          atag.setAttribute("target", "_blank");
          document.body.appendChild(atag);
          atag.click();
          document.body.removeChild(atag);
          resolve();
        }
      })
      .catch((err) => {
        resolve();
      });
  };

  const handleProduct = (e, loanType) => {
    e.preventDefault();
    e.stopPropagation();

    if (loanType === "offer") {
      props.apis.apiGetCustomerInfo().then((res) => {
        if (res?.status !== "COMPLETED") {
          swal2.fire({
            title: "Please complete your profile before applying for any loans",
            icon: "error",
            iconColor: "#FF4D4D",
            showCancelButton: false,
            timer: 4000,
          });
        } else {
          let payload = {
            appliedDevice: "Web",
          };
          props.apis
            .apiAddCustomerPreQualifiedApplication(payload)
            .then((res) => {
              if (res) {
                navigate(
                  `/app/loancalculationdocs?id=${res?.applicationId}&path=customerhome`
                );
              }
            });
        }
      });
    }
  };

  // ----------------------------------------------------------------
  // HANDLE CHANGE PASSWORD MODAL
  const openChangePasswordmodal = () => {
    changePasswordModalRef.current.click();
  };

  const closeChangePasswordmodal = () => {
    document.querySelector("#changepasswordModal #bsmodalclose").click();
    setChangepasswordData({});
    setPopupChangePasswordFields({});
  };

  const addChangepasswordData = () => {
    let fields = (changepasswordFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupChangePasswordFields(fields);
    setChangepasswordData(formdata);
    openChangePasswordmodal();
  };

  const handleChangePasswordSubmit = (e, data) => {
    console.log({ handleChangePasswordSubmit: data });
    if (data.newPassword !== data.confirmPassword) {
      swal2.fire({
        title: "Password and Confirm Password does not match",
        icon: "warning",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      let payload = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };

      props.apis.apiCustomerPasswordReset(payload).then((res) => {
        console.log({ apiPasswordReset: res });
        if (res.status === 200) {
          swal2
            .fire({
              title: "Password changed successfully, Please re-login",
              icon: "success",
              iconColor: "#FF4D4D",
            })
            .then((result) => {
              if (result.isConfirmed) {
                closeChangePasswordmodal();
                props?.apis?.applogout();
              }
            });
        }
      });
    }
  };

  const fetchData = () => {
    // props.apis.apiGetCustomerDashboardMoke().then((res) => {
    //   setDashboardData(res);
    // });
    setDashboardData([]);
  };

  useEffect(() => {
    fetchData();
    getApplications();
    // ----------------------------------------------------------------
    const { emailAddress } = props?.$store?.logged;
    const { isPasswordUpdated, role, fastLoanEligible } = props?.$store?.user;
    if (
      !isPasswordUpdated & (role !== _enum.PERSONAS.admin) &&
      emailAddress !== undefined
    ) {
      addChangepasswordData();
    }

    if (fastLoanEligible) {
      seFisfastLoanEligible(fastLoanEligible);
    }
  }, []);

  return (
    <>
      <MainPagetitle
        mainTitle="Dashboard"
        pageTitle="Dashboard"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="card p-4 bg_gradient br-12">
          <FlexCard
            title="Active Loan Details"
            flexOuterClass="bg_gradient"
            data={activeApplicationData && activeApplicationData?.appl_details}
            colspan_key="col-3"
            colspan_value="col-3"
            {...(hasActiveLoan
              ? {
                  hasPdfFile: true,
                  pdfIdKey: "loan_agreement",
                }
              : {})}
          />
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              className="btn btn-outline-primary-white"
              onClick={(...args) =>
                navigate(
                  `/app/myapplicationview?id=${editdata?.applicationId}&path=customerhome`
                )
              }
              disabled={!hasActiveLoan}
            >
              View Details
            </button>
            <button
              type="button"
              className="btn btn-primary-white ms-3"
              onClick={(...args) => handleShow(...args)}
              disabled={!hasActiveLoan}
            >
              Pay Now
            </button>
          </div>
        </div>
        <Card name="custom-tab" className="dz-card">
          <Card.Header className="border-none">
            <div>
              <Card.Title className="heading_title">Top Products</Card.Title>
            </div>
          </Card.Header>
          <div className="row g-3 px-3 mb-5">
            {products &&
              products
                ?.filter((v) =>
                  isFastLoanEligible === true
                    ? v?.loanType === "offer" ||
                      v?.loanType === "long_term_loan"
                    : v?.loanType !== "offer"
                )
                .map((item, i) => {
                  return (
                    <ProductCard
                      key={i}
                      title={item.title}
                      desc={item.desc}
                      image={item.image}
                      buttonType={item.buttonType}
                      buttonText={item.buttonText}
                      loanType={item.loanType}
                      {...(item?.directlink
                        ? {
                            link: item?.directlink,
                          }
                        : {
                            link: "/app/loancalculation?path=customerhome",
                          })}
                      handleProduct={(...args) => handleProduct(...args)}
                    />
                  );
                })}
          </div>
        </Card>
      </div>
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0 heading_title">
                    Application Status
                  </h4>
                </div>
                <AppTable
                  data={applications}
                  columns={columns}
                  reorder={true}
                  showPagination={false}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* below button hidden on UI */}
      <button
        className="btn btn-sm btn-outline-secondary d-none"
        onClick={(e) => addChangepasswordData(e)}
        ref={changePasswordModalRef}
        data-bs-toggle="modal"
        data-bs-target="#changepasswordModal"
      >
        <span>Change Password</span>
      </button>

      <LeadsCenteredModal
        id="changepasswordModal"
        title="Change Password"
        submitText="Chage Password"
        hidecancelbtn={true}
        data={changepasswordData}
        fields={popupChangePasswordFields}
        onSubmit={(...args) => handleChangePasswordSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeChangePasswordmodal()}
      />

      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal payment_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleSubmit}>
            <div className="payment_modal_content_box">
              <p className="content_title">How much you want to pay?</p>
              <div className="checkbox_container mb-4 flex-column">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="currentOutstanding"
                    name="communicationAddress"
                    id="currentOutstanding"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "currentOutstanding"}
                  />
                  <label class="form-check-label" for="currentOutstanding">
                    Current Outstanding
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="otherAmount"
                    name="communicationAddress"
                    id="otherAmount"
                    onChange={(...args) => handlePayment(...args)}
                    checked={checkValue === "otherAmount"}
                  />
                  <label class="form-check-label" for="otherAmount">
                    Other Amount
                  </label>
                </div>
              </div>
            </div>
            {checkValue === "otherAmount" && (
              <AppInput name="amount" label="Amount" type="number"></AppInput>
            )}
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Pay Now
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default constore(CustomerHome);
