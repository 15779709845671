import LoanApplicationsView from "../../../jsx/components/Dashboard/LoanApplications/loanapplicationsview";

const LoanApplicatiionsWrapper = () => {
  return <LoanApplicationsView />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.sales_lead,
  _enum.ROUTE_PERSONAS.underwriter,
  _enum.ROUTE_PERSONAS.cpa,
];
export default constore(LoanApplicatiionsWrapper);
