export default {
  apiGetUnderWriterUnassignedApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().UNDERWRITER_GET_UNASSIGNED_APPLICATIONS,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetUnderWriterRejectedApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().UNDERWRITER_GET_REJECTED_APPLICATIONS,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetUnderWriterInprogressApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().UNDERWRITER_GET_INPROGRESS_APPLICATIONS,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },
  apiGetUnderWriterSanctionedApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().UNDERWRITER_GET_SANCTIONED_APPLICATIONS,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },
  apiGetUnderWriterApprovedApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().UNDERWRITER_GET_APPROVED_APPLICATIONS,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },
  apiGetUnderWriterApplicationDetails(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_APPLICATION_DETAILS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUpdateUnderWriterApplicationAddress(data, urldata = {}) {
    return this.request(this.api().UNDERWRITER_UPDATE_APPLICATION_ADDRESS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUpdateUnderWriterApplicationIncome(data, urldata = {}) {
    return this.request(
      this.api().UNDERWRITER_UPDATE_APPLICATION_INCOME_DETAILS,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUnderWriterGetApplicationReview(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_APPLICATION_FINAL_REVIEW)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiAddUnderWriterAdditionalDocs(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_ADD_ADDITIONAL_DOCS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUpdateUnderWriterAdditionalDocs(data, urldata = {}) {
    return this.request(this.api().UNDERWRITER_UPDATE_ADDITIONAL_DOCS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiUnderWriterDeleteAdditionalDocs(urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_DELETE_ADDITIONAL_DOCS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUpdateUnderWriterPermanentAddressInfo(data, urldata = {}) {
    return this.request(
      this.api().UNDERWRITER_UPDATE_PERMANENT_ADDRESS_INFO,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUpdateUnderWriterReferenceDetails(data, urldata = {}) {
    return this.request(
      this.api().UNDERWRITER_UPDATE_CUSTOMER_REFERENCE_DETAILS,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUpdateUnderWriterAccountDetails(data, urldata = {}) {
    return this.request(
      this.api().UNDERWRITER_UPDATE_CUSTOMER_ACCOUNT_DETAILS,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiGetUnderwriterApplicationDedupCheck(data) {
    return this.request(this.api().UNDERWRITER_APPLICATION_DEDUP_CHECK, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiGetUnderwriterApplicationApplicationHistory(urldata = {}) {
    return this.request(this.api().UNDERWRITER_APPLICATION_STATUS_HISTORY)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiUnderwriterSelfAssignApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_SELF_ASSIGN_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderwriterReAssignApplication(data) {
    return this.request(this.api().UNDERWRITER_REASSIGN_APPLICATION, data)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterCreateApplicationQuery(data) {
    return this.request(this.api().UNDERWRITER_CREATE_APPLICATION_QUERY, data)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGetApplicationQuery(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_APPLICATION_QUERY)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetUnderWriterCibilDetails(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_CIBIL_DETAILS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGenerateCibilReport(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().UNDERWRITER_GENERATE_CIBIL_REPORT)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterAddAdditionalCibilInfo(data) {
    let payload = [...data];

    return this.request(
      this.api().UNDERWRITER_ADD_CIBIL_ADDITIONAL_INFO,
      payload
    )
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGetAdditionalCibilInfo(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_CIBIL_ADDITIONAL_INFO)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterUpdateAdditionalCibilInfo(data, urldata = {}) {
    return this.request(
      this.api().UNDERWRITER_UPDATE_CIBIL_ADDITIONAL_INFO,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUnderWriterDeleteAdditionalCibilInfo(urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_DELETE_CIBIL_ADDITIONAL_INFO)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterAddBsaInfo(data, urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_ADD_BSA_INFO, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGetBsaEnquiries(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_BSA_ENQUIRIES)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterAcceptQueryResponse(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_ACCEPT_QUERY_RESPONSE)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterRejectQueryResponse(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_REJECT_QUERY_RESPONSE)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterSanctionApplication(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_SANCTION_APPLICATION, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterRejectApplication(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_REJECT_APPLICATION, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterAddSalaryCreditInfo(data) {
    let payload = [...data];

    return this.request(
      this.api().UNDERWRITER_ADD_SALARY_CREDIT_INFO, payload)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterUpdateSalaryCreditInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(
      this.api().UNDERWRITER_UPDATE_SALARY_CREDIT_INFO,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // LOAN ACCOUNTS
  apiUnderWriterGenerateAgreement(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GENERATE_AGREEMENT, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterReGenerateAgreement(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_REGENERATE_AGREEMENT, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetUnderWriterLoanApplicationDetails(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().GET_LOAN_APPLICATION_DETAILS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetAllUnderWriterLoanAccounts(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().GET_ALL_LOAN_ACCOUNTS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetAllUnderWriterPredisbursedApplications(data, urldata = {}) {
    return this.request(this.api().GET_ALL_PREDISBURSED_APPLICATIONS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count
        }
      })
      .get();
  },

  apiGetUnderWriterPredisbursedApplicationByNumber(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().GET_PREDISBURSED_APPLICATION_BY_NUMBER)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterLoanAccountById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().GET_LOAN_ACCOUNT_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetAllActiveLoans(data, urldata = {}) {
    return this.request(this.api().GET_ACTIVE_LOANS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count
        }
      })
      .get();
  },

  apiGetAllClosedLoans(data, urldata = {}) {
    return this.request(this.api().GET_CLOSED_LOANS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count
        }
      })
      .get();
  },
  // LOAN REPAYMENTS

  apiAdminAddLoanPayment(data) {
    return this.request(this.api().ADMIN_ADD_LOAN_REPAYMENT, data)
      .exec()
      .log()
      .get();
  },
  apiAdminGetAllLoanRepayments(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_ALL_LOAN_PAYMENTS,)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        console.log({ res });
        return res
      })
      .get();
  },
  apiAdminCalculateInterest(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().ADMIN_CALCULATE_INTEREST)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiAdminLoanClosureAfterRepayment(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().ADMIN_LOAN_CLOSURE_AFTER_REPAYMENT, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGenerateNocToCustomer(urldata = {}) {
    return this.request(this.api().ADMIN_GENERATE_NOC_TO_CUSTOMER)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // ----------------------------------------------------------------
  apiUnderWriterApprovePostSanctionApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(
      this.api().UNDERWRITER_APPROVE_POST_SANCTION_APPLICATION
    )
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterPreDisburseApplication(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(
      this.api().UNDERWRITER_APPROVE_PRE_DISBURSE_APPLICATION,
      data
    )
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGenerateBsaReport(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GENERATE_BSA_REPORT)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterVerifyBsaStatus(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_VERIFY_BSA_STATUS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGetBsaReport(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().UNDERWRITER_GET_BSA_REPORT)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUnderWriterGeneratePennyDropPayment(data) {
    return this.request(this.api().UNDERWRITER_PENNY_DROP_PAYMENT, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiUnderWriterCheckPennyDropStatus(data) {
    return this.request(this.api().UNDERWRITER_CHECK_PENNY_DROP_STATUS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  // Signzy: Bank Statement Analysis

  apiUnderWriterBSAUpload(data) {
    return this.request(this.api().UNDERWRITER_BSA_UPLPAD, data)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiUnderWriterGetBSAInfo(urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_GET_BSA_INFO)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterAnalyzeBSAData(data) {
    return this.request(this.api().UNDERWRITER_ANALYZE_BSA, data)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterRefreshBSAStatus(urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_REFRESH_BSA_STATUS)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUnderWriterGetBSAReportSolo(urldata = mixins.params()) {
    return this.request(this.api().UNDERWRITER_GET_BSA_REPORT_SOLO)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetUnderWriterSanctionedsApplicationsMoke() {
    let data = [
      {
        leadId: "3453463",
        leadName: "John Doe",
        applicationNumber: "ABC1235",
        applicationDate: new Date().toISOString(),
        loanAmount: 25000,
        sanctioned_amount: 25000,
        roi: 10,
        tenure: 30,
        status: "Sanctioned",
      },
      {
        leadId: "3453463",
        leadName: "Suresh Vadla",
        applicationNumber: "ABC12346",
        applicationDate: new Date().toISOString(),
        loanAmount: 5000,
        sanctioned_amount: 5000,
        roi: 10,
        tenure: 40,
        status: "Post_sanctioned",
      },
      {
        leadId: "3453463",
        leadName: "John Doe",
        applicationNumber: "ABC1237",
        applicationDate: new Date().toISOString(),
        loanAmount: 25000,
        sanctioned_amount: 25000,
        roi: 10,
        tenure: 40,
        status: "Customer_Declined",
      },
    ];

    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterApprovedApplicationsMoke() {
    let data = [
      {
        leadId: "3453463",
        leadName: "John Doe",
        applicationNumber: "ABC1235",
        applicationDate: new Date().toISOString(),
        loanAmount: 25000,
        sanctioned_amount: 25000,
        tenure: 30,
        status: "Loan_Agreement",
      },
      {
        leadId: "3453463",
        leadName: "Suresh Vadla",
        applicationNumber: "ABC12346",
        applicationDate: new Date().toISOString(),
        loanAmount: 5000,
        sanctioned_amount: 5000,
        tenure: 40,
        status: "Approved",
      },
      {
        leadId: "3453463",
        leadName: "John Doe",
        applicationNumber: "ABC1237",
        applicationDate: new Date().toISOString(),
        loanAmount: 25000,
        sanctioned_amount: 25000,
        tenure: 40,
        status: "E_Signed",
      },
    ];

    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetUnderWriterPersonalInfoMoke() {
    let data = {
      user_id: "23424234",
      name: "Suresh Vadla",
      gender: "Male",
      date_of_birth: "23 Oct 1988",
      age: "31",
      mobile_no: "9989786866",
      isMobileVerified: true,
      isEmailVerified: true,
      email_id: "email@gmail.com",
      pincode: "500032",
      aadhar_no: "1234 5678 5432",
      pan_number: "PANG12345H",
      address: "House no. 49/E, Banjara Hills, Hyderabad",
      //
      name_as_per_application: "Suresh Vadla",
      name_as_per_aadhar_card: "Suresh Vadla",
      name_as_per_pan_card: "Suresh Vadla",
      aadhar_seeding_successful: "Yes",
      //
      communication_address: {
        residence_status: "Owned",
        pincode: "500032",
        city: "Hyderabd",
        address_1: "TNGO’s Colony , Nanakarmgudda",
        state: "Telangana",
        document_proof: "Voter ID",
      },
      //
      panFileMetaData: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
      // aadharFrontFileMetaData: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
      // aadharBackFileMetaData: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
      photoFileMetaData: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterBankInfoMoke() {
    let data = {
      bank_name: "ICICI",
      account_no: "214215322366",
      account_holder_name: "Suresh Vadla",
      bank_statement_period: "-",
      credit_seen: "-",
    };

    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterIncomeDetailsMoke() {
    let data = [
      {
        company_name: "Wipro",
        mode_of_salary: "Bank Transfer",
        monthly_gross_salary: 50000,
        office_email: "ashwipro@gmail.com",
        company_category: "",
        applicants_designation: "",
        DOJ: "",
        PF_deducted: "",
        PF_amount_deducted: "",
        monthly_net_take_salary: "",
        bank_name: "",
        final_net_salary: "",
        remarks: "",
        payslip1: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        payslip2: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        payslip3: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement1: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement2: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement3: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement4: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement5: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
        // bankstatement6: { fileId: "2c6825775d870b165fa2e0fe95c9f6fc" },
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterSummaryMoke() {
    let data = [
      {
        title: "Personal Info.",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
      {
        title: "Income Details",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
      {
        title: "BSA Info",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
      {
        title: "CIBIL",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterQueryMoke() {
    let data = [
      {
        title: "Personal Info.",
        status: "Discrepancy",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
        response: [{ panFileId: "2c6825775d870b165fa2e0fe95c9f6fc" }],
      },
      {
        title: "Income Details",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
      {
        title: "BSA Info",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
      {
        title: "CIBIL",
        status: "Approve",
        remarks:
          "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. ",
        colspan_key: "col-md-2",
        colspan_value: "col-md-10",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterRecommendationsMoke() {
    let data = [
      "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over.",
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterPostSanctionedDocsMoke() {
    let data = {
      permanent_address: {
        residence_status: "Owned",
        pin_code: "500032",
        city: "Hyderabd",
        address_1: "TNGO’s Colony , Nanakarmgudda",
        state: "Telangana",
        document_proof: "Voter ID",
      },
      reference_details_1: {
        name: "Santa Devi Kumari",
        mobile_no: "3254645765",
        relation: "Mother",
        colspan_key: "col-12 col-md-4",
        colspan_value: "col-12 col-md-4",
      },
      reference_details_2: {
        name: "Yasha Agarwal",
        mobile_no: "6789054356",
        relation: "Friend",
        colspan_key: "col-12 col-md-4",
        colspan_value: "col-12 col-md-4",
      },
      bank_account_details: {
        account_holder_name: "Suresh Vadla",
        account_no: "24364575686",
        bank_name: "ICICI",
        IFSC_code: "3647458",
      },
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterLoanDetailsMoke() {
    let data = [
      {
        leadId: "3453463",
        leadName: "Suresh Vadla",
        lan: "3453463",
        sanctioned_amount: 25000,
        roi: 10,
        tenure: "30 Days",
        underwriter: "Ashish Kumar",
        status: "Pre-Disbursement",
      },
      {
        leadId: "3453463",
        leadName: "John Doe",
        lan: "3453463",
        sanctioned_amount: 5000,
        roi: 10,
        tenure: "40 Days",
        underwriter: "Manish Khati",
        status: "Pre-Disbursement",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterActiveLoanDetailsMoke() {
    let data = {
      loan_application_no: "23424234",
      loan_amt: "2,00,000",
      tenure: "20 Days",
      roi: "10%",
      processing_fee: "Rs. 600",
      loan_start_date: "06-04-2024",
      loan_end_date: "06-04-2024",
      agreement_copy: "2c6825775d870b165fa2e0fe95c9f6fc",
      kfs: "2c6825775d870b165fa2e0fe95c9f6fc",
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterLoanBankDetailsMoke() {
    let data = {
      account_holder_name: "Suresh Vadla",
      account_no: "1423534745856",
      bank_name: "ICICI",
      IFSC_code: "2qq45236437",
      payment_ref_no: "32645865",
      payment_date: "10-04-2024",
      payment_status: "Online",
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetUnderWriterRepaymentsMoke() {
    let data = [
      {
        payment_date: new Date().toISOString(),
        payment_amount: 5000,
        payment_mode: "Online",
        outstanding: 5000,
        payment_status: "failed",
        payment_ref_no: "232534647",
        total_amount: 25000,
      },
      {
        payment_date: new Date().toISOString(),
        payment_amount: 5000,
        payment_mode: "Offline",
        outstanding: 5000,
        payment_status: "success",
        payment_ref_no: "232534647",
        total_amount: 25000,
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
};