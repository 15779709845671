import Dupecheck from "../../../jsx/components/Dashboard/DupeCheck/dupecheck";

const DupeCheckWrapper = () => {
  return <Dupecheck />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.underwriter,
  _enum.ROUTE_PERSONAS.cpa,
];
export default constore(DupeCheckWrapper);
