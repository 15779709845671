import React from "react";
import { Link } from "react-router-dom";

const ProductCard = (props) => {
  return (
    <div className="col-12 col-md-4 col-xl-4">
      <div className="product_card">
        <div className="body d-flex">
          <img
            src={props?.image}
            className="img-fluid me-4"
            alt="personalLoanImg"
          />
          <div className="content">
            <div>
              <h4 className="title">{props?.title}</h4>
              <p className="desc">{props?.desc}</p>
            </div>
            {props?.buttonType === "callback" ? (
              <>
                <button
                  className="btn btn-outline-primary"
                  onClick={(...args) =>
                    props.handleProduct(...args, props?.loanType)
                  }
                >
                  {props?.buttonText}
                </button>
              </>
            ) : (
              <Link
                to={props?.link}
                className={Object.className({
                  btn: true,
                  "btn-outline-primary": props?.buttonType === "active",
                  "btn-primary": props?.buttonType === "inactive",
                  "pe-none": props?.buttonType === "inactive",
                })}
                disabled={props?.buttonType === "inactive" ? true : false}
              >
                <span>
                  {props?.buttonType === "inactive"
                    ? "Coming Soon"
                    : props?.buttonType === "active" && props?.buttonText
                    ? props?.buttonText
                    : "Apply Now"}
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
