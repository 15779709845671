import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import LoanApplicationSearch from "./LoanApplicationSearch/LoanApplicationSearch";
import { store } from "../../../../utils/redux/store";

const LoanApplications = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const isCpa = props?.$store?.persona === _enum.ROUTE_PERSONAS.cpa;
  const isUnderWriter =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.underwriter;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const pendingapplicationsColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "ApplicationAddressInfo",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "applicationType",
      title: "Application Type",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "platform",
      title: "Platform",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const rejectedapplicationsColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "underwriter",
      title: "Underwriter",
      render: (text, record) => {
        return <>{record?.underwriter?.name || "-"}</>;
      },
    },
    {
      dataIndex: "ApplicationAddressInfo",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Tenure",
      render: (text, record) => {
        return <span>{text ? `${text} Days` : "N/A"}</span>;
      },
    },
    {
      dataIndex: "rejectedReason",
      title: "Remarks",
      render: (text, record) => {
        return <span>{text?.toTitleCase() || "-"}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const inprogressColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "customer",
      title: "Customer Type",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.customer?.customerType?.toTitleCase() || "-"}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "underwriter",
      title: "Underwriter",
      render: (text, record) => {
        return <>{record?.underwriter?.name || "-"}</>;
      },
    },
    {
      dataIndex: "ApplicationAddressInfo",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "duration",
      title: "Applied Tenure",
      render: (text) => {
        return <>{text} Days</>;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const sanctionedColumns = [
    {
      dataIndex: "customer",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{text?.customerName}</h6>
            <span>{record?.applicationId}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "underwriter",
      title: "Underwriter",
      render: (text, record) => {
        return <>{record?.underwriter?.name || "-"}</>;
      },
    },
    {
      dataIndex: "ApplicationAddressInfo",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return record?.customer?.mobileNumber;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Applied Loan Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmt",
      title: "Sanctioned Amount",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "sanctionedIRR",
      title: "ROI",
      render: (text) => {
        return <>{`${text} %`}</>;
      },
    },
    {
      dataIndex: "applicationLoanAccount",
      title: "Sanctioned Loan Tenure",
      render: (text, record) => {
        let tenure = record?.applicationLoanAccount?.sanctionedLoanTenure;
        return <>{tenure ? `${tenure} Days` : "-"}</>;
      },
    },
    {
      dataIndex: "applicationLoanAccount",
      title: "KFS",
      render: (text, record) => {
        return record.status === "ESIGNED" ? (
          <div className="docs_card">
            <a
              href={text?.signzyLoanAgreementLink}
              target="_blank"
              className="text-primary text-underline"
            >
              Download
            </a>
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(inprogressColumns);
  const [activeKey, setActiveKey] = useState("");
  const filterTabData = [
    {
      key: "pendingapplications",
      name: "Pending Applications",
    },
    {
      key: "inprogress",
      name: "In progress",
    },
    {
      key: "sanctioned",
      name: "Sanctioned",
    },
    {
      key: "approved",
      name: "Approved",
    },
    {
      key: "rejected",
      name: "Rejected",
    },
  ];

  const handleSelect = (e, key) => {
    let payload = {
      page: 1,
      limit: 10,
    };
    setSearch(payload);
    setActiveKey(key);
    navigate(`/app/loanapplications?activetab=${key}`);
  };

  const onPageChange = (pageData) => {
    setSearch((prevState) => ({
      ...prevState,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    }));
  };

  const getUnassignedApplications = (params = {}) => {
    let payload = {
      ...params,
    };
    console.log("getUnassignedApplications: ", payload);
    props.apis
      .apiGetUnderWriterUnassignedApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getRejectedApplications = (params = {}) => {
    let payload = {
      ...params,
    };

    props.apis
      .apiGetUnderWriterRejectedApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getApplications = (params = {}) => {
    let payload = {
      ...params,
    };

    props.apis
      .apiGetUnderWriterInprogressApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getSanctionedApplications = (params = {}) => {
    let payload = {
      ...params,
    };

    props.apis
      .apiGetUnderWriterSanctionedApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getApprovedApplications = (params = {}) => {
    let payload = {
      ...params,
    };

    props.apis
      .apiGetUnderWriterApprovedApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const view = (record) => {
    console.log({ record });
    let inprogressStatus = ["Under_writing", "Applied", "Query"];
    let sanctionedStatus = [
      "Sanctioned",
      "Post_sanctioned",
      "Customer_Declined",
    ];
    let approvedStatus = [];
    let activestatus = "";
    if (sanctionedStatus.includes(record.status)) {
      activestatus = "sanctioned";
    } else if (inprogressStatus.includes(record.status)) {
      activestatus = "inprogress";
    } else if (approvedStatus.includes(record.status)) {
      activestatus = "approved";
    }

    navigate(
      `/app/loanapplicationsview?id=${record?.applicationId}&status=${activeKey}&frompath=loanapplications&readonly=true`
    );
  };

  const edit = (record) => {
    console.log({ record });
    navigate(
      `/app/loanapplicationsview?id=${record?.applicationId}&frompath=loanapplications&status=${activeKey}&applicationStatus=${record?.status}`
    );
  };

  const softDeleteApplication = (record) => {
    console.log({ record });
    swal2
      .fire({
        title: "Are you sure you want to soft delete this application?",
        icon: "warning",
        iconColor: "#FF4D4D",
        showCancelButton: true,
        confirmButtonColor: "#FF4D4D",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiSoftDeleteApplication({ applicationId: record.applicationId })
            .then((res) => {
              swal2.fire({
                title: "Application Deleted",
                text: "Application deleted successfully !!",
                icon: "success",
                iconColor: "#FF4D4D",
                timer: 4000,
              });
              getData();
            });
        }
      });
  };

  const generateAgreement = (e) => {
    console.log(e);
    navigate(
      `/app/generateagreement?id=${e?.applicationId}&status=${activeKey}&path=generateagreement`
    );
  };

  const regenerateAgreement = (e) => {
    console.log(e);
    navigate(
      `/app/generateagreement?id=${e?.applicationId}&status=${activeKey}&path=regenerateagreement`
    );
  };

  const copyPresignedAgreementLink = (record) => {
    if (record.status !== "AGREEMENT") {
      swal2.fire({
        title: "Signed agreement link not found!",
        icon: "warning",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      let url = record?.applicationLoanAccount.presignedAgreementLink;
      navigator.clipboard.writeText(url);
      swal2.fire({
        title: "Copied to clipboard",
        icon: "success",
        timer: 2000,
        iconColor: "#FF4D4D",
      });
    }
  };
  // ----------------------------------------------------------------
  const [show, setShow] = useState(false);
  const [assignedId, setAssignedId] = useState([]);
  const [assignedToFields, setAssignedToFields] = useState([
    {
      name: "profileId",
      label: "Underwriter Name",
      type: "select",
      options: [],
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
    },
  ]);

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
    setSelectedRowKeys([]);
    setAssignedId([]);
  };

  const handleAssignedTo = (record) => {
    setAssignedId([record?.applicationId]);
    handleShow();
  };

  const handleAssignedToSubmit = (e, data) => {
    let payload = {
      underwriterId: data?.profileId,
      remarks: data?.remarks,
    };
    if (selectedRowKeys?.length > 0) {
      payload.applicationIds = selectedRowKeys;
    } else {
      payload.applicationIds = assignedId;
    }
    console.log({ data, payload });

    props.apis.apiUnderwriterReAssignApplication(payload).then((res) => {
      swal2.fire({
        title: "Loan Assigned To Underwriter Successfully",
        icon: "success",
        iconColor: "#FF4D4D",
        showCancelButton: false,
        timer: 3000,
      });
      handleClose();
      if (activeKey === "inprogress") {
        getApplications();
      }
      if (activeKey === "sanctioned") {
        getSanctionedApplications();
      }
      if (activeKey === "approved") {
        getApprovedApplications();
      }
    });
  };

  const getUnderwriters = () => {
    let payload = {
      role: _enum.PERSONAS.underwriter,
    };
    props.apis.apiGetEmployee(payload).then(({ data }) => {
      // console.log({ apiGetEmployee: data});
      let underWriterField = assignedToFields.find(
        (o) => o.name == "profileId"
      );
      underWriterField.options = data.options("profileId", "name");
      setAssignedToFields([...assignedToFields]);
    });
  };
  // ----------------------------------------------------------------
  // TABLE ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notSelected, setNotSelected] = useState(true);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if (activeKey === "approved") {
        if (record?.status === "ESIGNED") {
          return {
            disabled: false,
          };
        } else {
          return {
            disabled: true,
          };
        }
      }
      // console.log({ selectedRowKeys });
    },
  };

  const handleDisbursement = () => {
    console.log({ selectedRowKeys });

    props?.apis
      ?.apiUnderWriterPreDisburseApplication(
        {
          applicationIds: [...selectedRowKeys],
        },
        {
          applicationIds: [...selectedRowKeys],
        }
      )
      .then((res) => {
        swal2.fire({
          title: "Application Disbursed Successfully !!",
          icon: "success",
          iconColor: "#FF4D4D",
          showCancelButton: false,
          timer: 3000,
        });
        setSelectedRowKeys([]);
        getApprovedApplications();
      });
  };

  const downloadExcel = async () => {
    let payload = {
      exportStatus: _enum.APPLICATION_ROUTE_MAP[activeKey],
    };

    let startDateValue = document.querySelector("[name='startDate']")?.value;
    let endDateValue = document.querySelector("[name='endDate']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }

    props.apis.apiExportApplicationDetails(payload).then(async (res) => {
      // console.log({ res });
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `application_${new Date().toDate()}.xlsx`
        );
        // console.log(link);
        link.click();
      }
    });
  };

  useEffect(() => {
    selectedRowKeys.length > 0 ? setNotSelected(false) : setNotSelected(true);
  }, [selectedRowKeys]);

  // ----------------------------------------------------------------

  const handleRejectReview = (record) => {
    console.log({ record });

    swal2
      .fire({
        title:
          "Are you sure you want to reject the application?\n Please provide your remarks",
        icon: "info",
        iconColor: "#DE3232",
        html: '<div id="custom-select-container"></div>',
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          htmlContainer: "rejected_html_container",
          actions: "rejected_actions",
        },
      })
      .then((result) => {
        console.log({ result });
        if (result.isConfirmed) {
          let reasonSelectValue = document.querySelector(
            "[name='appinput-reasons'].form-group"
          )?.vnode.refs.select?.state.values[0]?.key;

          let otherReasonValue =
            document.querySelector("#reasonsremarks").value;

          let payload = {};

          if (reasonSelectValue === "OTHERS") {
            payload.rejectedReason = otherReasonValue;
          } else {
            payload.rejectedReason = reasonSelectValue;
          }

          console.log({ payload }, record);

          props.apis
            .apiUnderWriterRejectApplication(payload, {
              applicationId: record?.applicationId,
            })
            .then((res) => {
              swal2.fire({
                title: "Application Rejected",
                icon: "success",
                iconColor: "#FF4D4D",
                showCancelButton: false,
                timer: 3000,
              });
              navigate(`/app/loanapplications?activetab=approved`);
              getData();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    let container = document.getElementById("custom-select-container");
    let root = createRoot(container);
    root.render(
      <Provider store={store}>
        <AppInput
          name="reasons"
          label="Rejected Reasons"
          type="select"
          options={_enum.REJECTED_REASONS}
          onChange={(...args) => handleRejectedReasons(...args)}
          required={true}
        ></AppInput>
        <AppInput
          id="reasonsremarks"
          name="remarks"
          label="Remarks"
          type="textarea"
          required={true}
          outerclass="d-none"
        ></AppInput>
      </Provider>
    );
  };

  const handleRejectedReasons = (val) => {
    let reason = val[0]?.key;
    console.log({ reason });
    if (reason === "OTHERS") {
      document
        .querySelector('[name="appinput-remarks"]')
        .classList.remove("d-none");
    } else {
      document
        .querySelector('[name="appinput-remarks"]')
        .classList.add("d-none");
    }
  };
  // ----------------------------------------------------------------

  const getData = (payload) => {
    // clear table data from previous request
    setTableData([]);
    if (activeKey === "pendingapplications") {
      setColumns(pendingapplicationsColumns);
      getUnassignedApplications(payload);
    } else if (activeKey === "inprogress") {
      if (!isAdmin) {
        setColumns(
          inprogressColumns?.filter((v) => v.dataIndex !== "underwriter")
        );
      } else {
        setColumns(inprogressColumns);
      }
      getApplications(payload);
    } else if (activeKey === "sanctioned") {
      if (!isAdmin) {
        setColumns(
          sanctionedColumns?.filter((v) => v.dataIndex !== "underwriter")
        );
      } else {
        setColumns(sanctionedColumns);
      }
      getSanctionedApplications(payload);
    } else if (activeKey === "approved") {
      if (!isAdmin) {
        setColumns(
          sanctionedColumns?.filter(
            (v) => v.dataIndex !== "underwriter" && v.dataIndex !== "roi"
          )
        );
      } else {
        setColumns(sanctionedColumns.filter((v) => v.dataIndex !== "roi"));
      }
      getApprovedApplications(payload);
    } else if (activeKey === "rejected") {
      if (!isAdmin) {
        setColumns(
          rejectedapplicationsColumns?.filter(
            (v) => v.dataIndex !== "underwriter"
          )
        );
      } else {
        setColumns(rejectedapplicationsColumns);
      }
      getRejectedApplications(payload);
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  useEffect(() => {
    setColumns([]);
    setTableData([]);
    setRowcount(0);

    let payload = {
      ...search,
    };

    const str = window.location.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    console.log(params);

    if (params.hasOwnProperty("status")) {
      payload.status = params?.status;
    }

    console.log({ payload, search });

    getData(payload);
  }, [activeKey, search]);

  useEffect(() => {
    if (!isCpa) {
      getUnderwriters();
    }
    let paramsActiveTab = props.urlparams.params.activetab;

    if (isAdmin) {
      if (paramsActiveTab) {
        setActiveKey(paramsActiveTab);
      } else {
        setActiveKey("pendingapplications");
        navigate(`/app/loanapplications?activetab=pendingapplications`);
      }
    } else {
      if (paramsActiveTab) {
        setActiveKey(paramsActiveTab);
      } else {
        setActiveKey("inprogress");
        navigate(`/app/loanapplications?activetab=inprogress`);
      }
    }
    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  const hanldeInitiateRelook = (record) => {
    swal2
      .fire({
        title: "Are you sure want to relook this case?",
        icon: "warning",
        iconColor: "#FF4D4D",
        showCancelButton: true,
        confirmButtonColor: "#EB191A",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiReopenApplication({
              applicationNumber: record.applicationId,
            })
            .then((res) => {
              swal2.fire({
                title: "Application restored",
                text: "Application restored successfully !!",
                icon: "success",
                iconColor: "#FF4D4D",
                timer: 4000,
              });
              getRejectedApplications();
            });
        }
      });
  };

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="My Applications"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="filter_tab_container">
          {filterTabData
            ?.filter((v) => (!isAdmin ? v.key !== "pendingapplications" : v))
            .map((btn) => {
              return (
                <>
                  <button
                    type="button"
                    id={btn.key}
                    className={Object.className({
                      active: activeKey === btn.key,
                    })}
                    onClick={(...args) => handleSelect(...args, btn.key)}
                  >
                    {btn.name}
                  </button>
                </>
              );
            })}
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <LoanApplicationSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    activeKey={activeKey}
                  />
                </div>
                {isAdmin && (
                  <div className="action_btn_group text-end">
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary me-5"
                      onClick={(...args) => downloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                )}
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  {...(isAdmin
                    ? {
                        viewAction: (...args) => view(...args),
                        editable: false,
                        searchable: activeKey === "rejected",
                        searchActionText: "Initiate Relook",
                        searchAction: (e) => {
                          hanldeInitiateRelook(e);
                        },
                        agreementLink: false,
                        agreementRegenerateLink: false,
                        deletable: true,
                        deleteAction: (...args) =>
                          softDeleteApplication(...args),
                        rowSelection: rowSelection,
                      }
                    : isUnderWriter && activeKey === "approved"
                    ? {
                        rowSelection: rowSelection,
                        agreementLink: activeKey === "approved" ? true : false,
                        agreementRegenerateLink:
                          activeKey === "approved" ? true : false,
                        agreementLinkAction: (e) => generateAgreement(e),
                        agreementRegenerateLinkAction: (e) =>
                          regenerateAgreement(e),
                        viewAction: (e) => view(e),
                        editable: false,
                        copyable: true,
                        copyLinkAction: (e) => copyPresignedAgreementLink(e),
                        deletable: false,
                      }
                    : isUnderWriter && activeKey === "inprogress"
                    ? {
                        rowSelection: rowSelection,
                        viewAction: (e) => view(e),
                        editAction: (e) => edit(e),
                        editable: true,
                        deletable: false,
                      }
                    : isCpa
                    ? {
                        viewAction: (e) => view(e),
                        editable: false,
                        deletable: false,
                      }
                    : {
                        editable: activeKey === "rejected" ? false : true,
                        editAction: (e) => edit(e),
                        viewAction: (e) => view(e),
                        agreementLink: activeKey === "approved" ? true : false,
                        agreementRegenerateLink:
                          activeKey === "approved" ? true : false,
                        agreementLinkAction: (e) => generateAgreement(e),
                        agreementRegenerateLinkAction: (e) =>
                          regenerateAgreement(e),
                        deletable: false,
                      })}
                  assignedToLink={
                    ["inprogress", "approved", "sanctioned"].includes(
                      activeKey
                    ) && isAdmin
                      ? true
                      : ["inprogress"].includes(activeKey) && isUnderWriter
                      ? true
                      : false
                  }
                  assignedToLinkAction={(...args) => handleAssignedTo(...args)}
                  rejectReasonLink={
                    ["approved", "sanctioned"].includes(activeKey)
                      ? true
                      : false
                  }
                  rejectReasonLinkAction={(...args) =>
                    handleRejectReview(...args)
                  }
                  loading={isFetching}
                  agreementLinkActionText="Generate Agreement"
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
        {isUnderWriter && (
          <div
            className={Object.className({
              "row bottom_container": true,
              "d-none": activeKey !== "approved",
            })}
          >
            <div className="col-12 text-end">
              <button
                className={Object.className({
                  "btn btn-primary m-3": true,
                })}
                onClick={(e) => handleDisbursement(e)}
                disabled={notSelected}
              >
                Send for Disbursement
              </button>
            </div>
          </div>
        )}
      </div>
      {/* ASSIGNED TO MODAL */}
      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign To</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleAssignedToSubmit}>
            {assignedToFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Assign
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(LoanApplications);
