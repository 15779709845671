import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Button, Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import LoanDetailsSearch from "./LoanDetailsSearch/LoanDetailsSearch";
import { store } from "../../../../utils/redux/store";
import DupeCheckModal from "../DupeCheck/DupeCheckModal";

const LoanDetails = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const isCollections =
    props?.$store?.persona === _enum.ROUTE_PERSONAS.collections;
  // const isCollections = true;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);

  const disbursementColumns = [
    {
      dataIndex: "applicationLoanAccount",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">
              {record?.applicationLoanAccount?.borrowerName}
            </h6>
          </div>
        );
      },
    },
    {
      dataIndex: "application",
      title: "Underwriter Name",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.underwriter?.name}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "customer",
      title: "Customer Type",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.customer?.customerType?.toTitleCase() || "-"}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "LAN No.",
      render: (text, record) => {
        return <>{record?.applicationLoanAccount?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctioned_amount",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return (
          <>
            {record?.applicationLoanAccount?.sanctionedLoanAmount?.cur() || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "roi",
      title: "ROI",
      render: (text, record) => {
        return (
          <>{`${record?.applicationLoanAccount?.sanctionedLoanIRR}%` || "-"}</>
        );
      },
    },
    {
      dataIndex: "tenure",
      title: "Sanctioned Tenure",
      render: (text, record) => {
        return (
          <>{record?.applicationLoanAccount?.sanctionedLoanTenure || "-"}</>
        );
      },
    },
    {
      dataIndex: "processingFee",
      title: "Processing Fees",
      render: (text, record) => {
        return (
          <>{record?.applicationLoanAccount?.sanctionedLoanPF?.cur() || "-"}</>
        );
      },
    },
    {
      dataIndex: "underwriter",
      title: "Underwriter",
      render: (text, record) => {
        return <>{record?.underwriter?.name || "-"}</>;
      },
    },
    {
      dataIndex: "application",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.application?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.status?.toTitleCase()}</span>
              <i className="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const activeColumns = [
    {
      dataIndex: "borrowerName",
      title: "Customer Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record.borrowerName}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "application",
      title: "Underwriter Name",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.application?.underwriter?.name}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "application",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.application?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "customer",
      title: "Customer Type",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.customer?.customerType?.toTitleCase() || "-"}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "LAN No.",
      render: (text, record) => {
        return <>{record?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanStartDate",
      title: "Loan Start Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "sanctionedLoanEndDate",
      title: "Loan End Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "loanPaymentMode",
      title: "Payment Type",
    },
    {
      dataIndex: "application",
      title: "Channel",
      render: (text, record) => {
        return <>{record?.application?.channel || "-"}</>;
      },
    },
    {
      dataIndex: "loanAccountStatus",
      title: "Application Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.application?.status?.toLowerCase()] ||
          "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.application?.status?.toTitleCase()}</span>
              <i className="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "signzyLoanAgreementLink",
      title: "KFS",
      render: (text, record) => {
        return record?.isLoanAgreementSigned === true ? (
          <div className="docs_card">
            <a
              href={text}
              target="_blank"
              className="text-primary text-underline"
            >
              Download
            </a>
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Loan Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.loanAccountStatus?.toLowerCase()] ||
          "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record?.loanAccountStatus?.toTitleCase()}</span>
              <i className="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];
  const closedColumns = [
    {
      dataIndex: "borrowerName",
      title: "Name",
      render: (text, record) => {
        return (
          <div>
            <h6 className="text-primary">{record.borrowerName}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "application",
      title: "Underwriter Name",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.application?.underwriter?.name}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "application",
      title: "Location",
      render: (text, record) => {
        return (
          <>
            {record?.application?.ApplicationAddressInfo?.filter(
              (v) => v?.addressType === "COMMUNICATION"
            )[0]?.city || "-"}
          </>
        );
      },
    },
    {
      dataIndex: "customer",
      title: "Customer Type",
      render: (text, record) => {
        return (
          <div>
            <h6>{record?.customer?.customerType?.toTitleCase() || "-"}</h6>
          </div>
        );
      },
    },
    {
      dataIndex: "lan",
      title: "Application no",
      render: (text, record) => {
        return <>{record?.loanAccountNumber || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Loan Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "sanctionedLoanStartDate",
      title: "Loan Start Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "sanctionedLoanEndDate",
      title: "Loan End Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "tenure",
      title: "Tenure",
      render: (text, record) => {
        return <>{record?.sanctionedLoanTenure || "-"}</>;
      },
    },
    {
      dataIndex: "waiverAmount",
      title: "Waiver",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "application",
      title: "Channel",
      render: (text, record) => {
        return <>{record?.application?.channel || "-"}</>;
      },
    },
    {
      dataIndex: "totalRepaymentAmtTillDate",
      title: "Total Paid Amount",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "loanClosingDate",
      title: "Loan Closer Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
  ];
  const [columns, setColumns] = useState(disbursementColumns);
  const [activeKey, setActiveKey] = useState("");
  const filterTabData = [
    {
      key: "pending",
      name: "Pending for Disbursement",
    },
    {
      key: "active",
      name: "Active Loans",
    },
    {
      key: "closed",
      name: "Closed Loans",
    },
  ];

  const handleSelect = (e, key) => {
    let payload = {
      page: 1,
      limit: 10,
    };
    setSearch(payload);
    setActiveKey(key);
    navigate(`/app/loandetails?activetab=${key}`);
  };

  const onPageChange = (pageData) => {
    setSearch((prevState) => ({
      ...prevState,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    }));
  };

  const getPendingDisbursedApplications = (params = {}) => {
    let payload = { ...params };

    props.apis
      .apiGetAllUnderWriterPredisbursedApplications(payload)
      .then(async ({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getActiveApplications = (params = {}) => {
    let payload = { ...params };

    props.apis.apiGetAllActiveLoans(payload).then(({ data, total }) => {
      console.log({ data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const getClosedApplications = (params = {}) => {
    let payload = { ...params };

    props.apis.apiGetAllClosedLoans(payload).then(({ data, total }) => {
      console.log({ data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const edit = (record) => {
    console.log({ record });
    let { applicationLoanAccount } = record;
    let path;
    if (activeKey === "pending") {
      path = `/app/loandetailspendingview?id=${applicationLoanAccount?.applicationId}&lanid=${applicationLoanAccount?.loanAccountNumber}&name=${applicationLoanAccount?.borrowerName}&status=${activeKey}&frompath=loandetails`;
    } else if (activeKey === "active") {
      path = `/app/loandetailsactiveview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails`;
    } else if (activeKey === "closed") {
      setSelectedLan(record);
      setShowReloanModal(true);
    }
    navigate(path);
  };

  const view = (record) => {
    console.log({ record });
    let { applicationLoanAccount } = record;

    let path;
    if (activeKey === "pending") {
      path = `/app/loandetailspendingview?id=${applicationLoanAccount?.applicationId}&lanid=${applicationLoanAccount?.loanAccountNumber}&name=${applicationLoanAccount?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    } else if (activeKey === "active") {
      path = `/app/loandetailsactiveview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    } else if (activeKey === "closed") {
      path = `/app/loandetailsclosedview?id=${record?.applicationId}&lanid=${record?.loanAccountNumber}&name=${record?.borrowerName}&status=${activeKey}&frompath=loandetails&readonly=true`;
    }
    navigate(path);
  };

  // ----------------------------------------------------------------
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupdata, setPopupdata] = useState({});

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    closeModal();
    setPopupdata([]);
  };
  const viewApplLogs = (record) => {
    let { applicationId } = record;
    let payload = {
      applicationId,
    };
    props.apis
      .apiGetUnderwriterApplicationApplicationHistory(payload)
      .then((res) => {
        setPopupdata(res);
        setOpenCanvas(true);
      });
  };

  const handleManualDisbursement = (record) => {
    console.log({ record });
    let { applicationId, loanAccountNumber, borrowerName } =
      record?.applicationLoanAccount;
    let path = `/app/manualdisbursementview?id=${applicationId}&lanid=${loanAccountNumber}&name=${borrowerName}&status=${activeKey}`;
    navigate(path);
  };

  const updatePendingRecord = (record) => {
    let id = record?.applicationLoanAccount?.loanAccountNumber;

    props.apis.apiGetTransactionStatus({ refereceId: id }).then((res) => {
      console.log({ res });
    });
  };

  // ----------------------------------------------------------------

  const handleRejectReview = (record) => {
    console.log({ record });

    swal2
      .fire({
        title:
          "Are you sure you want to reject the application?\n Please provide your remarks",
        icon: "info",
        iconColor: "#DE3232",
        html: '<div id="custom-select-container"></div>',
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          htmlContainer: "rejected_html_container",
          actions: "rejected_actions",
        },
      })
      .then((result) => {
        console.log({ result });
        if (result.isConfirmed) {
          let reasonSelectValue = document.querySelector(
            "[name='appinput-reasons'].form-group"
          )?.vnode.refs.select?.state.values[0]?.key;

          let otherReasonValue =
            document.querySelector("#reasonsremarks").value;

          let payload = {};

          if (reasonSelectValue === "OTHERS") {
            payload.rejectedReason = otherReasonValue;
          } else {
            payload.rejectedReason = reasonSelectValue;
          }

          console.log({ payload }, record.applicationLoanAccount.applicationId);

          props.apis
            .apiUnderWriterRejectApplication(payload, {
              applicationId: record.applicationLoanAccount.applicationId,
            })
            .then((res) => {
              swal2.fire({
                title: "Application Rejected",
                icon: "success",
                iconColor: "#FF4D4D",
                showCancelButton: false,
                timer: 3000,
              });
              navigate(`/app/loanapplications?activetab=approved`);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    let container = document.getElementById("custom-select-container");
    let root = createRoot(container);
    root.render(
      <Provider store={store}>
        <AppInput
          name="reasons"
          label="Rejected Reasons"
          type="select"
          options={_enum.REJECTED_REASONS}
          onChange={(...args) => handleRejectedReasons(...args)}
          required={true}
        ></AppInput>
        <AppInput
          id="reasonsremarks"
          name="remarks"
          label="Remarks"
          type="textarea"
          required={true}
          outerclass="d-none"
        ></AppInput>
      </Provider>
    );
  };

  const handleRejectedReasons = (val) => {
    let reason = val[0]?.key;
    console.log({ reason });
    if (reason === "OTHERS") {
      document
        .querySelector('[name="appinput-remarks"]')
        .classList.remove("d-none");
    } else {
      document
        .querySelector('[name="appinput-remarks"]')
        .classList.add("d-none");
    }
  };
  // ----------------------------------------------------------------

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (record) => {
    setShow(true);
    setCurrentNoc(record);
  };
  const [currentNoc, setCurrentNoc] = useState({});

  const generateNOC = () => {
    let payload = {
      loanAccountNumber: currentNoc?.loanAccountNumber,
    };
    props.apis.apiGenerateNocToCustomer(payload).then((res) => {
      if (res) {
        swal2.fire({
          title: "NOC Generated Successfully",
          icon: "success",
          iconColor: "#DE3232",
          timer: 3000,
          confirmButtonText: "Close",
        });
        handleClose();
      }
    });
  };

  const handleViewLogs = (record) => {
    console.log({ viewLogs: record });
  };

  // ----------------------------------------------------------------
  const [showReloanModal, setShowReloanModal] = useState(false);
  const [selectedLan, setSelectedLan] = useState(null);
  const [reloanFields, setReloanFields] = useState([
    {
      name: "reLoanEligibility",
      label: "Case is eligible for Re-loan?(Y/N)",
      type: "select",
      options: _enum.YES_OR_NO_BOOLEAN,
      required: true,
    },
  ]);
  const handleReloanClose = () => setShowReloanModal(false);

  const handleReloan = (record) => {
    setSelectedLan(record);
    setShowReloanModal(true);
  };

  const handleReloanSubmit = (e, data) => {
    let payload = {
      reLoanEligibility: JSON.parse(data?.reLoanEligibility),
    };

    let urlData = {
      customerId: selectedLan?.customerId,
    };

    console.log({ urlData, payload, selectedLan });

    props.apis.apiAdminUpdateCustomerDetails(payload, urlData).then((res) => {
      if (res) {
        setShowReloanModal(false);
        getData();
      }
    });
  };
  // ----------------------------------------------------------------

  const downloadLoanDetails = () => {
    let payload = {};
    payload.status = activeKey?.toUpperCase();

    let startDateValue = document.querySelector("[name='startDate']")?.value;
    let endDateValue = document.querySelector("[name='endDate']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }

    props.apis.apiExportLoanDetails(payload).then(async (res) => {
      // console.log({ res });
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `loan_details_${new Date().toDate()}.xlsx`
        );
        // console.log(link);
        link.click();
      }
    });
  };
  // ----------------------------------------------------------------

  const getData = (payload) => {
    // clear table data from previous request
    setTableData([]);

    if (activeKey === "pending") {
      setColumns(disbursementColumns);
      getPendingDisbursedApplications(payload);
    } else if (activeKey === "active") {
      setColumns(activeColumns);
      getActiveApplications(payload);
    } else if (activeKey === "closed") {
      setColumns(closedColumns);
      getClosedApplications(payload);
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  useEffect(() => {
    setColumns([]);
    setTableData([]);
    setRowcount(0);

    let payload = {
      ...search,
    };

    const str = window.location.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    console.log({ params });

    if (params.hasOwnProperty("status")) {
      payload.status = params?.status;
    } else if (params.hasOwnProperty("channel")) {
      payload.channel = params?.channel;
    }

    console.log({ payload, search });
    getData(payload);
  }, [activeKey, search]);

  useEffect(() => {
    let paramsActiveTab = props.urlparams.params.activetab;
    if (paramsActiveTab) {
      setActiveKey(paramsActiveTab);
    } else if (isCollections) {
      setActiveKey("active");
      navigate(`/app/loandetails?activetab=active`);
    } else {
      setActiveKey("pending");
      navigate(`/app/loandetails?activetab=pending`);
    }
    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Loan details"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="filter_tab_container">
          {filterTabData
            ?.filter((v) => (isCollections ? v.key !== "pending" : v))
            ?.map((btn) => {
              return (
                <>
                  <button
                    type="button"
                    id={btn.key}
                    className={Object.className({
                      active: activeKey === btn.key,
                    })}
                    onClick={(...args) => handleSelect(...args, btn.key)}
                  >
                    {btn.name}
                  </button>
                </>
              );
            })}
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <LoanDetailsSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    activeKey={activeKey}
                  />
                </div>
                <div
                  className={Object.className({
                    "header_btn_box my-3": true,
                    hidden:
                      !isAdmin ||
                      (activeKey !== "active" && activeKey !== "closed"),
                  })}
                >
                  <a
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadLoanDetails(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Bulk Download</span>
                  </a>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(e) => view(e)}
                  {...(!isAdmin
                    ? {
                        editable: false,
                      }
                    : isAdmin && activeKey === "pending"
                    ? {
                        updateable: true,
                        updateAction: (e) => updatePendingRecord(e),
                        editAction: (e) => edit(e),
                        manualDisbursement: true,
                        manualDisbursementAction: (e) =>
                          handleManualDisbursement(e),
                        rejectReasonLink: true,
                        rejectReasonLinkAction: (...args) =>
                          handleRejectReview(...args),
                      }
                    : isAdmin && activeKey === "closed"
                    ? {
                        issueNocLink: true,
                        editable: true,
                        editAction: (e) => edit(e),
                        reloanLink: true,
                        issueNocLinkAction: (...args) => handleShow(...args),
                        reloanLinkAction: (...args) => handleReloan(...args),
                      }
                    : isCollections
                    ? {
                        viewlogs: true,
                        editable: true,
                        viewLogsAction: (...args) => handleViewLogs(...args),
                        editAction: (e) => edit(e),
                      }
                    : {
                        editAction: (e) => edit(e),
                      })}
                  deletable={false}
                  {...(activeKey === "active" || activeKey === "closed"
                    ? {
                        appllogLink: true,
                        viewApplLogsAction: (e) => viewApplLogs(e),
                      }
                    : {})}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DupeCheckModal
        title="Application Log"
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
      />
      {/* PREVIEW MODAL */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        className="preview_agreement_modal noc_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="py-0">
          <div className="heading_box">
            <Modal.Title>AFI- Dhanvriddhi Loans</Modal.Title>
            <Modal.Title>No Objection Certificate</Modal.Title>
          </div>
          <p className="date">
            Date : <span>{new Date().dateToDDMMYYYY(new Date())}</span>
          </p>
          <p className="loanNum">
            Loan No. : <span>{currentNoc?.loanAccountNumber}</span>
          </p>
          <br />
          <br />
          <p className="para_title">Mr/Ms</p>
          <p className="paragraph_text">
            This is to certify that <span>{currentNoc?.borrowerName}</span> who
            had taken a short-term loan from Ayaan Finserve India Private
            Limited for Rs.
            <span>{currentNoc?.sanctionedLoanAmount?.cur()}</span>. on
            <span>
              {new Date(currentNoc?.sanctionedLoanStartDate).dateToDDMMYYYY(
                new Date(currentNoc?.sanctionedLoanStartDate)
              )}
            </span>
            has fully repaid the loan amount. Hence there are no more dues from
            Mr/Ms. <span>{currentNoc?.borrowerName}</span> against this loan
            taken from Ayaan Finserve India Private Limited.
          </p>
          <br />
          <br />

          <img src={imagepaths.nocLogo} alt="Ayaan Stamp" />
          <br />
          <br />

          <div className="contact_box_container">
            <div className="contact_box">
              <i className="fa-solid fa-phone"></i>
              <span>0120-4832057 / 0120-3229778</span>
            </div>
            <div className="contact_box mx-5">
              <i className="fa-solid fa-envelope"></i>
              <span>info@afiloans.co.in</span>
            </div>
            <div className="contact_box">
              <i className="fa-solid fa-globe"></i>
              <span>https://afiloans.co.in</span>
            </div>
          </div>

          <div className="card_footer mt-4 text-center">
            <p className="fw-bold">Ayaan Finserve India Private Limited</p>
            <p>A-118 , Ground Floor , Vikas Marg, Shakarpur, Delhi- 110092</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary-lite"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => generateNOC(...args)}
          >
            Generate
          </button>
        </Modal.Footer>
      </Modal>

      {/* RELOAN MODAL */}
      <Modal
        show={showReloanModal}
        onHide={(...args) => handleReloanClose(...args)}
        className="customer_application_modal br-normal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Re-loan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleReloanSubmit}>
            {reloanFields?.map((field, i) => (
              <AppInput {...field}></AppInput>
            ))}
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => handleReloanClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(LoanDetails);
