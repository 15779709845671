import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
export class DupeCheckModal extends Component {
  constructor(props) {
    super(props);
    (this.state = {
      rowcount: 0,
    }),
      (this.columns = [
        {
          dataIndex: "changedBy",
          title: "Changed By",
          render: (text, record) => {
            return (
              <div>
                <h6 className="text-primary">{text || "-"}</h6>
              </div>
            );
          },
        },
        {
          dataIndex: "name",
          title: "Name",
          render: (text, record) => {
            return (
              <>
                {record?.changedBy === "ADMIN"
                  ? "Admin"
                  : record?.changedBy === "CUSTOMER" && record?.customer
                  ? record?.customer?.customerName
                  : record?.changedBy === "UNDERWRITER" && record?.underwriter
                  ? record?.underwriter?.name
                  : "-"}
              </>
            );
          },
        },
        {
          dataIndex: "status",
          title: "Status",
          render: (text, record) => {
            let mappedStatus =
              _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
            return (
              <Dropdown className="status-dropdown no-arrow pe-none">
                <Dropdown.Toggle as="div" className={mappedStatus}>
                  <span>{record.status.toTitleCase()}</span>
                  <i class="fa-solid fa-chevron-down ms-2"></i>
                </Dropdown.Toggle>
              </Dropdown>
            );
          },
        },
        {
          dataIndex: "createdAt",
          title: "Date & Time",
          render: (text, record) => {
            let dt = new Date(text);
            let date = new Date(dt).dateToDDMMYYYY(dt);
            let time = dt.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            return <span>{`${date} ${time}`}</span>;
          },
        },
      ]);
  }
  componentDidMount() {
    window.DupeCheckModal = this;
  }
  render() {
    let { props, columns } = this;
    let { rowcount } = this.state;
    let { id, title, show, onClose, modalSize, data } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppTable
          data={data}
          columns={columns}
          total={rowcount}
          reorder={true}
          viewable={false}
          editable={false}
          deletable={false}
        ></AppTable>

        <div className="row mt-4">
          <div className="col-xl-6 mb-3"></div>
          <div
            className={Object.className({
              "col-xl-6 mb-3 text-end d-flex justify-content-end": true,
            })}
          >
            <Link
              to={"#"}
              onClick={onClose}
              className={Object.className({
                "btn btn-primary": true,
              })}
            >
              Close
            </Link>
          </div>
        </div>
      </AppCanvas>
    );
  }
}

export default DupeCheckModal;
