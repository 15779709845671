import Loanapplications from "../../../jsx/components/Dashboard/LoanApplications/loanapplications";

const LoanApplicatiionsWrapper = () => {
  return <Loanapplications />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.sales_lead,
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
  _enum.ROUTE_PERSONAS.cpa,
];
export default constore(LoanApplicatiionsWrapper);
