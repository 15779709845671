import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Cibil from "./Cibil/Cibil";
import FinalReview from "./FinalReview/FinalReview";
import IncomeDetails from "./IncomeDetails/IncomeDetails";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import PostSanctionedDocs from "./PostSanctionedDocs/PostSanctionedDocs";
import Queries from "./Queries/Queries";
import CollectionDocs from "../LoanDetails/CollectionDocs/CollectionDocs";

const LoanApplicationsView = (props) => {
  const activeStatus = props?.urlparams?.params?.status;
  const frompath = props?.urlparams?.params?.frompath;
  const [customerName, setCustomerName] = useState("");

  const [activeKey, setActiveKey] = useState("personalinfo");
  const tabData = [
    { key: "personalinfo", name: "Personal Info." },
    { key: "incomedetails", name: "Income & BSA Info." },
    { key: "cibil", name: "CIBIL" },
    { key: "collectiondocs", name: "Documents" },
    { key: "queries", name: "Queries" },
    { key: "finalreview", name: "Final Review" },
    { key: "postsanctioneddocs", name: "Post Sanctioned Documents" },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const handleGetName = (data) => {
    setCustomerName(data);
  };

  const changeTab = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveKey(key);
    scrollToTop();
  };

  const handleFinalSubmit = () => {
    swal2.fire({
      title: "Loan Approved Successfully",
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
  };

  const handlePostSanction = (e, data) => {
    console.log({ data });
    swal2.fire({
      title: "Post Sanction Approved Successfully",
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
  };

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${activeStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  const getPageTitle = () => {
    let title;
    if (frompath === "loanapplications") {
      title = `My Application`;
    }
    if (frompath === "dupecheck") {
      title = `Dedup Check`;
    }
    return title;
  };

  const getPageIcon = () => {
    let icon;
    if (frompath === "loanapplications") {
      icon = imagepaths.applicationCheckImg;
    }
    if (frompath === "dupecheck") {
      icon = imagepaths.DupeCheckIconActive;
    }
    return icon;
  };

  return (
    <div>
      <MainPagetitle
        mainTitle={getPageTitle()}
        back={customerName || ""}
        backLink={getBankLink()}
        pageTitle={getPageTitle()}
        parentTitle={"Home"}
        pageIcon={getPageIcon()}
      />
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1 p-4">
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Nav as="ul" className="nav-tabs border-0">
            {tabData
              .filter(
                (item) =>
                  activeStatus === "sanctioned" ||
                  activeStatus === "approved" ||
                  item.key !== "postsanctioneddocs"
              )
              .filter(
                (item) =>
                  activeStatus !== "sanctioned" || item.key !== "finalreview"
              )
              .map((data, i) => (
                <Nav.Item as="li" key={i}>
                  <Nav.Link
                    eventKey={data.key}
                    className="text-dark bg-transparent"
                  >
                    {data.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
          <Tab.Content className="pt-4">
            {activeKey === "personalinfo" && (
              <Tab.Pane eventKey="personalinfo">
                <PersonalInfo
                  changeTab={(...args) => changeTab(...args)}
                  onGetName={handleGetName}
                />
              </Tab.Pane>
            )}
            {activeKey === "incomedetails" && (
              <Tab.Pane eventKey="incomedetails">
                <IncomeDetails changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "cibil" && (
              <Tab.Pane eventKey="cibil">
                <Cibil changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "collectiondocs" && (
              <Tab.Pane eventKey="collectiondocs">
                <CollectionDocs changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "queries" && (
              <Tab.Pane eventKey="queries">
                <Queries changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "finalreview" && (
              <Tab.Pane eventKey="finalreview">
                <FinalReview changeTab={(...args) => changeTab(...args)} />
              </Tab.Pane>
            )}
            {activeKey === "postsanctioneddocs" && (
              <Tab.Pane eventKey="postsanctioneddocs">
                <PostSanctionedDocs
                  changeTab={(...args) => changeTab(...args)}
                  handlePostSanction={(...args) => handlePostSanction(...args)}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default constore(LoanApplicationsView);
