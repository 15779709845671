export const CpaMenuList = [
  {
    title: "Application Tracker",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loanapplications",
    id: "loanapplications",
  },
  {
    title: "Dedup Check",
    iconStyle: imagepaths.DupeCheckIcon,
    iconActive: imagepaths.DupeCheckIconActive,
    to: "/app/dupecheck",
    id: "dupecheck",
  },
];
